import { ClickAwayListener, Divider, MenuItem } from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { getAllDataFromPages } from '../../actions/commonActions';
import { useListCustomer } from '../../hooks/dashboard';
import { MuiTextField } from '../MuiComponents/FormInputs';
import SearchIcon from '../SvgIcons/SearchIcon';
import Typography from '../Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from "@mui/icons-material/Add";
import Button from '../Button';
import Storage from '../../utils/Storage';
import { AlertContext } from '../SuccessOrFailedModal';
import axios from "axios";
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { LIST_EASM } from '../../hooks/utils/constants';
import { LayoutContext } from '../../Providers/LayoutProvider';

const GlobalCompanySelection = () => {

    const [popup] = useContext(AlertContext);
    const [inputValue, setInputValue] = useState('');
    const [typingTimeOut, setTypingTimeout] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [currentComponent, setCurrentComponent] = useState(0);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const queryClient = useQueryClient();
    const location = useLocation(); // Get current route
    const currentPath = location.pathname; // Example: "/dashboard"
    const navigate = useNavigate();

    const { data, fetchNextPage, hasNextPage, isFetching } = useListCustomer({
        pageSize: 30,
        search: inputValue,
    });

    const { ref: inViewRef, inView } = useInView();


    const customers = useMemo(
        () =>
            getAllDataFromPages(data?.pages, 'customers').map(({ company_name: companyName, id, is_genpt_enabled, easm_enabled }) => ({
                label: companyName,
                id,
                is_genpt_enabled,
                easm_enabled
            })),
        [data]
    );

    // console.log("Storage.decryptStorageData ", JSON.parse(Storage.decryptStorageData('company_id')))
    const [selectedCustomer, setSelectedCustomer] = useState(JSON.parse(Storage.decryptStorageData('company_id')) || customers[0]);


    useEffect(() => {

        if (customers && !Storage.decryptStorageData('company_id')) {
            setSelectedCustomer(customers[0])
            // Storage.encryptStorageData('company_id', JSON.stringify(customers[0]))
        }

    }, [customers])

    useEffect(() => {
        async function updateSelectedCustomer() {
            if (selectedCustomer) {
                Storage.encryptStorageData('company_id', JSON.stringify(selectedCustomer));
                Storage.encryptStorageData('easm_enabled', JSON.stringify(selectedCustomer?.easm_enabled));
                Storage.encryptStorageData('genpt_enabled', JSON.stringify(selectedCustomer?.is_genpt_enabled));
                setSelectedCustomer(selectedCustomer);
                axios.defaults.headers.common.company_id = Storage.getData('company_id');
            }
        }
        updateSelectedCustomer();
    }, [selectedCustomer]);

    useEffect(() => {
        async function fetchNextPageIfInView() {
            if (inView) {
                await fetchNextPage();
            }
        }
        fetchNextPageIfInView();
    }, [inView]);



    // console.log('selectedCustomer', selectedCustomer)


    const onChangeCustomer = (currentCustomer) => async () => {
        if (currentCustomer.id !== selectedCustomer?.id) {

            popup({
                title: "Are you sure?",
                description: <span>Updating the customer to <span className='text-primary-800 font-bold'>{currentCustomer?.label}</span> will refresh all data and screens for the new selection.</span>,
                okayText: "Yes",
                cancelText: "No"
            }).then(async () => {
                setSelectedCustomer(currentCustomer);
                const pathname = location.pathname;
                if (
                    (pathname.includes('/dashboard') && pathname != '/admin/dashboard') ||
                    //Monitoring
                    (pathname.includes('/monitoring/') && (pathname.endsWith('/summary') || pathname.endsWith('/domain') || pathname.endsWith('/subdomains')) ||
                        //Attack Surface
                        pathname.includes('/attack_surface/subdomain')
                    )) {
                    setSearchValue('');
                    setInputValue('');
                    setIsDropdownOpen(false);
                    window.location = `/admin/dashboard/${currentCustomer?.id}/ctem_dashboard`
                    if (pathname.includes('ctem_dashboard')) {
                        window.location.reload();
                    }

                } else {
                    setSearchValue('');
                    setInputValue('');
                    setIsDropdownOpen(false);
                    window.location.reload();
                }

            });


        }
    };

    const onInputChange = (event) => {
        const currentInputValue = event.target.value.toLowerCase();
        setSearchValue(currentInputValue);
        if (typingTimeOut) {
            clearTimeout(typingTimeOut);
        }
        setTypingTimeout(
            setTimeout(() => {
                setInputValue(currentInputValue);
            }, 500)
        );
    };

    const onKeyDown = (e) => {
        e.stopPropagation();
    }

    // const handleDropdownToggle = (e) => {
    //     e.stopPropagation();
    //     setIsDropdownOpen(!isDropdownOpen);
        
    // };

    // console.log("isDropdownOpen ", isDropdownOpen)


    // useEffect(() => {
    //     // Function to handle clicks outside of the dropdown
    //     const handleClickOutside = (event) => {
    //         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //             setIsDropdownOpen(false);
    //         }
    //     };
    //     document.addEventListener('mousedown', handleClickOutside);

    //     // Clean up event listener
    //     return () => document.removeEventListener('mousedown', handleClickOutside);
    // }, []);



    return (
        // <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
            <div className="relative inline-flex items-center" ref={dropdownRef}>
                <MuiTextField
                    variant="filled"
                    label={selectedCustomer ? 'Selected Customer' : "Select a customer"}
                    select
                    // onClick={handleDropdownToggle}
                    sx={{
                        backgroundColor: "transparent",
                        border: "1px solid #A69595",
                        borderRadius: "4px",
                        "& .MuiFilledInput-root": {
                            height: '40px',
                            backgroundColor: "transparent",
                            "&:before, &:after": {
                                borderBottom: "none !important",
                            },
                            "&.Mui-focused": {
                                backgroundColor: "transparent",
                                // Explicitly target the input element in focused state
                                "& .MuiSelect-select": {
                                    backgroundColor: "transparent",
                                    // Remove the inset shadow that might appear on focus
                                    boxShadow: "none",
                                },
                            },
                            "&:hover, &:focus, &.Mui-active": {
                                backgroundColor: "transparent",
                            },
                            "& .MuiInputBase-input": {
                                fontWeight: 700,
                            }
                        },
                    }}
                    className="text-left !w-[300px] !m-0 !p-0"
                    SelectProps={{
                        IconComponent: KeyboardArrowDownIcon,
                        value: selectedCustomer || '',
                        displayEmpty: true,
                        renderValue: ({ label }) => label,
                        MenuProps: {
                            open: isDropdownOpen,
                            PaperProps: {
                                style: {
                                    maxHeight: 275,
                                },
                            },
                            MenuListProps: {
                                style: {
                                    padding: 0,
                                },
                            },
                        },
                        onOpen: () => setIsDropdownOpen(true),
                        onClose: () =>  setIsDropdownOpen(false)
                    }}
                >
                    <div className="p-2.5 sticky top-0 bg-white z-10">
                        <div className="border border-grey1 rounded-[5px] p-1 w-full flex">
                            <SearchIcon />
                            <input
                                onChange={onInputChange}
                                autoFocus
                                className="inline-block align-middle flex-grow ml-4 outline-none w-full"
                                placeholder="Search here..."
                                onKeyDown={onKeyDown}
                                value={searchValue}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>
                    </div>
                    <div>
                        {customers.map((item) => (
                            <React.Fragment key={item.id}>
                                <MenuItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onChangeCustomer(item)();
                                    }}
                                    selected={selectedCustomer?.id === item.id}
                                    className={`${selectedCustomer?.id === item.id && '!bg-primary-500 !text-white'}`}
                                >
                                    <div className="flex p-2">
                                        <Typography variant="caption" className="self-center ml-2">
                                            {item.label}
                                        </Typography>
                                    </div>
                                </MenuItem>
                                <Divider variant="middle" className="!m-0" />
                            </React.Fragment>
                        ))}
                        {customers?.length === 0 && (
                            <div className="text-center p-2">No Customers</div>
                        )}
                    </div>
                    {hasNextPage && <div className="h-[10px]" ref={inViewRef}></div>}
                </MuiTextField>
            </div>
        // </ClickAwayListener>
    );
};

export default GlobalCompanySelection;

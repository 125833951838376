import React from "react";
import KeyInsights from "./KeyInsights";

const KeyInsightsSection = (props) => {
  const { keyInsights, companyId = null, detailedData = [], role, type = null, assetId = null } = props;
  console.log('_==-keyInsights', keyInsights);
  return (
    <div className="grid grid-cols-12 ctem-key-insights">
        <div className='col-span-12 sm:col-span-12'>
          <KeyInsights
            companyId={companyId}
            data={keyInsights} 
            detailedData={detailedData}
            role={role}
            type={type}
            assetId={assetId}
          />
        </div>
    </div>
  );
};

export default KeyInsightsSection;

import Select from "react-select";



const ReactSelectSingleSelect = (props) => {
  
  const { options,styles, onChange, value, placeholder, components, isSearchable, menuIsOpen } = props

  return (
    <Select
      options={options}
      styles={styles}
      onChange={onChange}
      placeholder={placeholder}
      components={components}
      isSearchable={isSearchable}
      value={value}
      menuIsOpen={menuIsOpen}
    />
  );
};

export default ReactSelectSingleSelect;

import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Collapse } from "@mui/material";
import Typography from "../../../../../components/Typography";
import OpenFindingsOverview from "../../VADashboard/openFindingCountOverview";
import BarChartWidget from "../../../../../components/Widgets/BarChartWidget";
import CounterChartWidget from "../../../../../components/Widgets/CounterChartWidget";
import { assetCriticalityColors, associatedMalwareColors, confirmedRTIColors, exploitabilityBreakdownColors, statusBreakdownColors, vaSeverityColors, potentialRTIColors, vulnerabilityByAgeColors } from "../../../../../utils/constants/asset";
import LineChartWidget from "../../../../../components/Widgets/LineChartWidget";
import { qualysSeverityBreakDownLines } from "../../../../../utils/constants/chart";
import { useLocation, useNavigate } from "react-router-dom";
import OverallVAOverview from "./overview/OverallVAOverview";
import { ExpandMore } from "@mui/icons-material";
import { useGenVAOverall, useGenVAOverallInsight } from "../../../../../hooks/asset";
import styles from "../../../Admin/Dashboard/dashboard.module.scss";
import { useValidateLogin } from "../../../../../hooks/auth";
import GenVAIcon from "../../../../../components/SvgIcons/GenVAIcon";
import UncheckTick from "../../../../../components/SvgIcons/UncheckTick";
import moment from "moment";
import CheckedTick from "../../../../../components/SvgIcons/CheckedTick";
import { LayoutContext } from "../../../../../Providers/LayoutProvider";
import MuiTabs from "../../../../../components/MuiComponents/MuiTabs";
import { TransitionGroup } from "react-transition-group";
import MainLoader from "../../../../../components/Loader/MainLoader";
import SimpleBarChart from "../../../../../components/Widgets/simpleBarChart";
import KeyInsightsSection from "../../../../../components/KeyInsights";
import Storage from "../../../../../utils/Storage";

const OverallVADashboard = (props) => {
    const navigate = useNavigate();
    const { data: userData } = useValidateLogin();
    const user = userData?.user;
    const role = user?.role

    let { search } = useLocation();
    const assetId = 7;
    const { mainUrl } = useContext(LayoutContext)

    let { data: genVAOverallData, isFetching: isGenVAOverallFetching } = useGenVAOverall();
    let { data: genVAOverallInsightData, isFetching: isGenVAOverallInsightFetching } = useGenVAOverallInsight();
    
    const [initialState, updateInitialState] = useState(false);

    const companyEnabled = Storage.decryptData(genVAOverallData?.companyEnabled, Storage.getData("jwt.siemba")) === "false" ? false : true;
    const assetsConfigured = Storage.decryptData(genVAOverallData?.assetsConfigured, Storage.getData("jwt.siemba")) === "false" ? false : true;
    const scanExist = Storage.decryptData(genVAOverallData?.scanExist, Storage.getData("jwt.siemba")) === "false" ? false : true;

    useEffect(() => {
        if (
            !companyEnabled || 
            !assetsConfigured || 
            !scanExist
        ) {
            updateInitialState(true);
        } else {
            updateInitialState(false);
        }
    }, [genVAOverallData]);

    const convertQueryParamsStringToQueryParamsObject = (string) => {
        let queryParams = {};
        // remove first letter from query string
        string = string.substring(1);

        // convert string to array
        const queryParamsArray = string.split("&");
    
        // covert each index query to object
        queryParamsArray.forEach((queryParam) => {
            // split param by =
            const queryParamArray = queryParam.split("=");
            queryParams[queryParamArray[0]] = queryParamArray[1];
        });
        return queryParams;
    };

    const [tabIndex, setTabIndex] = useState(0);
    const searchParams = convertQueryParamsStringToQueryParamsObject(search);
    
    const handleFindingsRedirection = (type, value = '') => {
        let url = `${mainUrl}/va/all_findings/?`;

        value = value.split(' ').join('_'); // Join the value string with underscores
        if (type === "RTI-Confirmed" || type || "RTI-Potential") {
            if (value === 'Patch_Not_Available') {
                value = "No_Patch";
            } else if (value === 'Public_Exploit') {
                value = "Exploit_Public";
            } else if (value === 'Actively_Attacks') {
                value = "Active_Attacks";
            } else if (value === 'Zero-Day') {
                value = "Zero_Day";
            } else if (value === 'CISA_Known_Exploited_Vulnerabilities') {
                value = "Cisa_Known_Exploited_Vulns";
            }
        }
        
        switch (type.toLowerCase()) {
            case "confirmed":
                if (value) {
                    url += `type=confirmed&status=open&severity=${value}`;
                } else {
                    url += `type=confirmed&status=open`;
                }
                break;
            case "potential":
                if (value) {
                    url += `type=potential&status=open&severity=${value}`;
                } else {
                    url += `type=potential&status=open`;
                }
                break;
            case "status":
                url += `type=confirmed&status=all`;
                break;
            case "rti-exploitability":
                url += `RTI=Easy_Exploit,Exploit_Public,Exploit_Kit&type=confirmed&status=open`;
                break;
            case "rti-malware":
                url += `RTI=Malware,Wormable&type=confirmed&status=open`;
                break;
            case "rti-confirmed":
                url += `RTI=${value}&type=confirmed&status=open`;
                break;
            case "rti-potential":
                url += `RTI=${value}&type=potential&status=open`;
                break;
            default:
                break;
        }
        navigate(url);
    }

    const exploitabilityBreakdown = [
        {
            name: 'Yes',
            value: genVAOverallData?.data?.findings_with_possible_exploits,
        },
        {
            name: 'No',
            value: genVAOverallData?.data?.confirmedFindingsCount - genVAOverallData?.data?.findings_with_possible_exploits,
        },
    ];

    const associatedMalware = [
        {
            name: 'Yes',
            value: genVAOverallData?.data?.findings_with_possible_malware,
        },
        {
            name: 'No',
            value: genVAOverallData?.data?.confirmedFindingsCount - genVAOverallData?.data?.findings_with_possible_malware,
        },
    ];

    const assetCriticality = [
        {
            name: "mission_critical",
            value: genVAOverallData?.data?.asset_criticality?.mission_critical,
        },
        {
            name: "significant_impact",
            value: genVAOverallData?.data?.asset_criticality?.significant_impact,
        },
        {
            name: "business_critical",
            value: genVAOverallData?.data?.asset_criticality?.business_critical,
        },
        {
            name: "limited_impact",
            value: genVAOverallData?.data?.asset_criticality?.limited_impact,
        },
        {
            name: "negligible_impact",
            value: genVAOverallData?.data?.asset_criticality?.negligible_impact,
        },
    ]

    useEffect(() => {
        const searchParams = convertQueryParamsStringToQueryParamsObject(search);
        const { status } = searchParams;
        if (status) {
            setTabIndex(1);
        }
    }, [search]);

    return (
        <>
            {(isGenVAOverallFetching) && <MainLoader />}
            {
                initialState ?
                    genVAOverallData &&
                        <div className="initial-state">  
                            <div className={`${styles.overview} padding-0`}>
                                <div className="grid grid-cols-12 gap-x-3">
                                    <div className={`xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 ctem-top-banner`}>
                                        <div className={styles.background}></div>
                                        <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                                            <div className="grid grid-cols-12 gap-x-3 mb-4">
                                                <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6 left-section">
                                                    <GenVAIcon />
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="grid grid-cols-12">
                                            <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                                                <Typography variant={'h5'} className={`font-bold leading-[140%]`}>
                                                Hi{' '}
                                                {user?.first_name
                                                    ? user?.first_name
                                                    : user?.last_name
                                                    ? user?.last_name
                                                    : ''}
                                                , <span className="text-primary-500">Welcome to GenVA</span>
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-12 mt-3">
                                            <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                                                <p className="font-semibold">
                                                    GenVA helps you analyze and manage vulnerabilities in your infrastructure assets. With aiso insights it ensures a clear, actionable view of your security posture.
                                                </p>
                                                <p className="font-semibold mt-3">Here is how  you can benefit from GenVA:</p>
                                                <p className="font-semibold mt-3">
                                                    <span className="text-primary-500">1. Review Findings:</span> Explore the vulnerabilities detected across your infrastructure in the dashboard.
                                                </p>
                                                <p className="font-semibold mt-3">
                                                    <span className="text-primary-500">2. Plan Remediation:</span> Leverage insights to address vulnerabilities efficiently.
                                                </p>
                                                <p className="font-semibold mt-3">
                                                    <span className="text-primary-500">3. Monitor Continuously:</span> Regularly check for updates as new data is synced.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <Typography variant={'h6'} className={`font-bold leading-[140%] mt-5 mb-5`}>
                                    Please follow the below steps to set up GenVA:
                                </Typography>
                                <Accordion 
                                    className="mb-5" 
                                    defaultExpanded={!companyEnabled} 
                                    disabled={!initialState || companyEnabled}
                                >
                                    <AccordionSummary>
                                        <Typography variant={'h6'} className={`font-bold flex leading-[140%] mt-5 mb-5 ${!genVAOverallData?.companyEnabled || (genVAOverallData?.companyEnabled) ? 'text-primary-500' : ''}`}> 
                                            <span className="mr-2">{
                                                companyEnabled ? 
                                                    <CheckedTick /> 
                                                : 
                                                    <UncheckTick />}
                                            </span>
                                            <span className="mr-1">Step 1 - </span> {`${companyEnabled ? 'Subscribed' : 'Subscribe'}`}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="!p-0 !px-4 !pb-4">
                                        <p className="grey-color font-semibold">
                                            By subscribing to GenVA, you’ll gain comprehensive insights into potential weaknesses within your system.
                                        </p>
                                        <p className="grey-color font-semibold mt-5">
                                            Email at <span className="text-primary-500">support@siemba.io</span> for more details.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion 
                                    className="mb-5" 
                                    defaultExpanded={initialState && companyEnabled && !assetsConfigured} disabled={!initialState || !companyEnabled || assetsConfigured}
                                >
                                    <AccordionSummary className="mb-5">
                                        <Typography variant={'h6'} className={`font-bold flex leading-[140%] mt-5 mb-5 ${(companyEnabled && !assetsConfigured) || (companyEnabled && assetsConfigured) ? 'text-primary-500' : ''}`}>
                                            <span className="mr-2">{assetsConfigured ? <CheckedTick /> : <UncheckTick />}</span><span className={"mr-1"}>Step 2 - </span>
                                            {
                                                user?.role === "siemba_tester" || user?.role === "customer_engineer" ? 
                                                    (assetsConfigured ? `Assets Assigned` : 'Assign Assets') 
                                                : 
                                                    (assetsConfigured ? `Assets Configured` : 'Configure Assets') 
                                            }
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="!p-0 !px-4 !pb-4">
                                        <p className="grey-color font-semibold">
                                            {user?.role === "siemba_tester" || user?.role === "customer_engineer" ? `You don’t have any Assets Assigned.` : "You don't have any Assets configured."}
                                        </p>
                                        <p className="grey-color font-semibold mt-5">
                                            {
                                                user?.role === "siemba_tester" || user?.role === "customer_engineer" ? 
                                                    `Please contact your admin to get assets assigned to you.` 
                                                : 
                                                    <div>
                                                        Email at <span className="text-primary-500">support@siemba.io</span> to configure Assets.
                                                    </div>
                                            }
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion defaultExpanded={initialState && companyEnabled && assetsConfigured} disabled={!initialState || !companyEnabled || !assetsConfigured}>
                                    <AccordionSummary>
                                        <Typography variant={'h6'} className={`font-bold leading-[140%] flex mt-5 mb-5 ${companyEnabled && assetsConfigured ? 'text-primary-500' : ''}`}> 
                                            <span className="mr-2">{scanExist ? <CheckedTick /> : <UncheckTick />}</span><span className="mr-1">Step 3 -</span> Start your First Assessment
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="!p-0 !px-4 !pb-4">
                                        <p className="grey-color font-semibold">
                                            We’re as excited as you are. 
                                        </p>
                                        <p className="grey-color font-semibold mt-5">
                                            Please contact <span className="text-primary-500">support@siemba.io</span> for initiating your First Assessment.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                :
                    <div className="vuln-assessment-dashboard va-overall-dashboard">
                        <div className='overview-section p-0'>
                            <div className='overview'>
                                <OverallVAOverview 
                                    assetId={assetId}
                                    mainUrl={mainUrl}
                                    genVAOverallData={genVAOverallData} 
                                    genVAOverallInsightData={genVAOverallInsightData}
                                    handleFindingsRedirection={handleFindingsRedirection}
                                />
                            </div>
                        </div>
                        {
                            genVAOverallInsightData?.data &&
                                <KeyInsightsSection companyId={genVAOverallInsightData?.companyId} keyInsights={genVAOverallInsightData?.data} role = {role} type="genva-overall" />
                        }
                        <div className="grid grid-cols-12">
                            <div className="xl:col-span-8 lg:col-span-8 md:col-span-8 sm:col-span-8">
                                <div className='open-finding-count-overall open-findings-count'>
                                    <div className='content'>
                                        <div className="tab-layout">
                                            <Typography variant={"h7"} className={"text-headings"}>{"Open Findings Count"}</Typography>
                                            <div>
                                                <MuiTabs
                                                    tabs={["All", "External", "Internal"]}
                                                    value={tabIndex}
                                                    onChange={(e, value) => {
                                                        setTabIndex(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="open-confirmed-findings-content flex">
                                            <div>
                                                <OpenFindingsOverview 
                                                    type='confirmed-findings'
                                                    handleFindingsRedirection={handleFindingsRedirection}
                                                    totalFindingsCount={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.confirmedFindingsCount) || 0 
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalConfirmedFindingsCount) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalConfirmedFindingsCount) || 0
                                                    }
                                                    urgent={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.confirmedFindingsSeverity?.urgent) || 0
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalConfirmedFindingsSeverity?.urgent) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalConfirmedFindingsSeverity?.urgent) || 0
                                                    }
                                                    critical={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.confirmedFindingsSeverity?.critical) || 0
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalConfirmedFindingsSeverity?.critical) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalConfirmedFindingsSeverity?.critical) || 0
                                                    }
                                                    serious={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.confirmedFindingsSeverity?.serious) || 0
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalConfirmedFindingsSeverity?.serious) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalConfirmedFindingsSeverity?.serious) || 0
                                                    }
                                                    medium={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.confirmedFindingsSeverity?.medium) || 0
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalConfirmedFindingsSeverity?.medium) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalConfirmedFindingsSeverity?.medium) || 0
                                                    }
                                                    minimum={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.confirmedFindingsSeverity?.minimum) || 0
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalConfirmedFindingsSeverity?.minimum) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalConfirmedFindingsSeverity?.minimum) || 0
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <OpenFindingsOverview 
                                                    type='potential-findings'
                                                    handleFindingsRedirection={handleFindingsRedirection}
                                                    totalFindingsCount={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.potentialFindingsCount) || 0
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalPotentialFindingsCount) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalPotentialFindingsCount) || 0
                                                    }
                                                    urgent={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.potentialFindingsSeverity?.urgent) || 0
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalPotentialFindingsSeverity?.urgent) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalPotentialFindingsSeverity?.urgent) || 0
                                                    }
                                                    critical={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.potentialFindingsSeverity?.critical) || 0
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalPotentialFindingsSeverity?.critical) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalPotentialFindingsSeverity?.critical) || 0
                                                    }
                                                    serious={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.potentialFindingsSeverity?.serious) || 0
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalPotentialFindingsSeverity?.serious) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalPotentialFindingsSeverity?.serious) || 0
                                                    }
                                                    medium={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.potentialFindingsSeverity?.medium) || 0
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalPotentialFindingsSeverity?.medium) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalPotentialFindingsSeverity?.medium) || 0
                                                    }
                                                    minimum={
                                                        tabIndex === 0 ? Number(genVAOverallData?.data?.potentialFindingsSeverity?.minimum) || 0
                                                        : tabIndex === 1 ? Number(genVAOverallData?.data?.ExternalPotentialFindingsSeverity?.minimum) || 0
                                                        : tabIndex === 2 && Number(genVAOverallData?.data?.InternalPotentialFindingsSeverity?.minimum) || 0
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-4">
                                <div className="mt-4">
                                    <CounterChartWidget 
                                        chartClassName={"h-[220px]"} className={"!my-0 !py-2.5"}
                                        data={assetCriticality || []} title={"Asset Criticality"}
                                        colors={assetCriticalityColors} 
                                        labelPosition={'bottom'}
                                        labelFullWidth={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <Accordion className="no-shadow" defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={
                                    <div className="border-radius-full bg-secondary-700">
                                        <ExpandMore color="#fff" htmlColor="#fff" />
                                    </div>
                                    }
                                    aria-controls="all-subdomain-content"
                                    id="all-subdomain-content"
                                >
                                    <p variant="subtitle1" class="subtitle1 py-4 text-headings">Threat Metrics</p>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{ maxHeight: '912px', overflowY: 'auto', padding: '0 16px 16px' }}
                                >
                                    <div className="grid grid-cols-12">
                                        <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6 box-shadow border-radius-10 mr-5">
                                            <div>
                                                <CounterChartWidget 
                                                    type="RTI-Exploitability"
                                                    chartClassName={"h-[195px]"} className={"!my-0 !py-2.5"}
                                                    data={exploitabilityBreakdown || []} title={"Findings with Possible Exploits"}
                                                    colors={exploitabilityBreakdownColors} 
                                                    labelPosition={'bottom'}
                                                    showLabelCount={'Yes'}
                                                    action={handleFindingsRedirection}
                                                />
                                            </div>
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6 box-shadow border-radius-10">
                                            <div>
                                                <CounterChartWidget 
                                                    type="RTI-Malware"
                                                    chartClassName={"h-[195px]"} className={"!my-0 !py-2.5"}
                                                    data={associatedMalware || []} title={"Findings with Possible Malware"}
                                                    colors={associatedMalwareColors} 
                                                    labelPosition={'bottom'}
                                                    showLabelCount={'Yes'}
                                                    action={handleFindingsRedirection}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-x-3 mt-5">
                                        <div className='xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 box-shadow border-radius-10'>
                                            <SimpleBarChart 
                                                className={"p-0 !pt-0 !pb-0 mb-4 h-auto"}
                                                height={200}
                                                colors={confirmedRTIColors} 
                                                hideLabel={true}
                                                angle={-45}
                                                xLabelHeight={90}
                                                textAnchor={"end"}
                                                data={[
                                                    {
                                                        name: "High Data Loss",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.high_data_loss)
                                                    },
                                                    {
                                                        name: "High Lateral Movement",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.high_lateral_movement)
                                                    },
                                                    {
                                                        name: "Denial of Service",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.denial_of_service)
                                                    },
                                                    {
                                                        name: "Patch Not Available",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.no_patch)
                                                    },
                                                    {
                                                        name: "Privilege Escalation",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.privilege_escalation)
                                                    },
                                                    {
                                                        name: "Unauthenticated Exploitation",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.unauthenticated_exploitation)
                                                    },
                                                    {
                                                        name: "Remote Code Execution",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.remote_code_execution)
                                                    },
                                                    {
                                                        name: "Actively Attacks",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.active_attacks)
                                                    },
                                                    {
                                                        name: "Malware",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.malware)
                                                    },
                                                    {
                                                        name: "Zero-Day",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.zero_day)
                                                    },
                                                    {
                                                        name: "Public Exploit",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.exploit_public)
                                                    },
                                                    {
                                                        name: "Predicted High Risk",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.predicted_high_risk)
                                                    },
                                                    {
                                                        name: "Easy Exploit",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.easy_exploit)
                                                    },
                                                    {
                                                        name: "Exploit Kit",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.exploit_kit)
                                                    },
                                                    {
                                                        name: "Wormable",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.wormable)
                                                    },
                                                    {
                                                        name: "Solorigate Sunburst",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.solorigate_sunburst)
                                                    },
                                                    {
                                                        name: "Ransomware",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.ransomware)
                                                    },
                                                    {
                                                        name: "CISA Known Exploited Vulnerabilities",
                                                        value: Number(genVAOverallData?.data?.open_confirmed_finding_rti?.cisa_known_exploited_vulns)
                                                    },
                                                ] || []} title={"Open Confirmed Finding Real-Time Threat Indicators(RTI)"}
                                                action={handleFindingsRedirection}
                                                type="RTI-Confirmed"
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-x-3 mt-5">
                                        <div className='xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 box-shadow border-radius-10'>
                                            <SimpleBarChart 
                                                className={"p-0 !pt-0 !pb-0 mb-4 h-auto"}
                                                height={200}
                                                colors={potentialRTIColors} 
                                                hideLabel={true}
                                                angle={-45}
                                                xLabelHeight={90}
                                                textAnchor={"end"}
                                                data={[
                                                    {
                                                        name: "High Data Loss",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.high_data_loss)
                                                    },
                                                    {
                                                        name: "High Lateral Movement",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.high_lateral_movement)
                                                    },
                                                    {
                                                        name: "Denial of Service",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.denial_of_service)
                                                    },
                                                    {
                                                        name: "Patch Not Available",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.no_patch)
                                                    },
                                                    {
                                                        name: "Privilege Escalation",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.privilege_escalation)
                                                    },
                                                    {
                                                        name: "Unauthenticated Exploitation",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.unauthenticated_exploitation)
                                                    },
                                                    {
                                                        name: "Remote Code Execution",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.remote_code_execution)
                                                    },
                                                    {
                                                        name: "Actively Attacks",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.active_attacks)
                                                    },
                                                    {
                                                        name: "Malware",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.malware)
                                                    },
                                                    {
                                                        name: "Zero-Day",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.zero_day)
                                                    },
                                                    {
                                                        name: "Public Exploit",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.exploit_public)
                                                    },
                                                    {
                                                        name: "Predicted High Risk",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.predicted_high_risk)
                                                    },
                                                    {
                                                        name: "Easy Exploit",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.easy_exploit)
                                                    },
                                                    {
                                                        name: "Exploit Kit",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.exploit_kit)
                                                    },
                                                    {
                                                        name: "Wormable",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.wormable)
                                                    },
                                                    {
                                                        name: "Solorigate Sunburst",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.solorigate_sunburst)
                                                    },
                                                    {
                                                        name: "Ransomware",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.ransomware)
                                                    },
                                                    {
                                                        name: "CISA Known Exploited Vulnerabilities",
                                                        value: Number(genVAOverallData?.data?.open_potential_finding_rti?.cisa_known_exploited_vulns)
                                                    },
                                                ] || []} title={"Open Potential Finding Real-Time Threat Indicators(RTI)"}
                                                action={handleFindingsRedirection}
                                                type="RTI-Potential"
                                            />
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="mt-5">
                        <Accordion className="no-shadow" defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={
                                    <div className="border-radius-full bg-secondary-700">
                                        <ExpandMore color="#fff" htmlColor="#fff" />
                                    </div>
                                    }
                                    aria-controls="all-subdomain-content"
                                    id="all-subdomain-content"
                                >
                                    <p variant="subtitle1" class="subtitle1 py-4 text-headings">Efficiency Metrics</p>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{ maxHeight: '912px', overflowY: 'auto', padding: '0 16px 16px' }}
                                >
                                    <div className="grid grid-cols-12 gap-x-3">
                                        <div className='xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-4 box-shadow border-radius-10 mr-4'>
                                            <div className='asset-statistics'>
                                                <div className='content'>
                                                    <div>
                                                        <CounterChartWidget 
                                                            chartClassName={"h-[180px]"} 
                                                            className={"!my-0 !py-2.5"}
                                                            data={[
                                                                {
                                                                    name: 'New',
                                                                    value: genVAOverallData?.data?.status_breakdown?.new,
                                                                },
                                                                {
                                                                    name: 'Active',
                                                                    value: genVAOverallData?.data?.status_breakdown?.active,
                                                                },
                                                                {
                                                                    name: 'Retest Ready',
                                                                    value: genVAOverallData?.data?.status_breakdown?.retest_ready,
                                                                },
                                                                {
                                                                    name: 'Re-Opened',
                                                                    value: genVAOverallData?.data?.status_breakdown?.re_opened,
                                                                },
                                                                {
                                                                    name: 'False Positive',
                                                                    value: genVAOverallData?.data?.status_breakdown?.false_positive,
                                                                }
                                                            ] || []} title={"Status Breakdown"}
                                                            colors={statusBreakdownColors} 
                                                            labelPosition={'bottom'}
                                                            type="Status"
                                                            action={handleFindingsRedirection}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='xl:col-span-8 lg:col-span-8 md:col-span-8 sm:col-span-8 box-shadow border-radius-10'>
                                            <SimpleBarChart 
                                                className={"p-0 !pt-0 !pb-0 mb-4 h-auto"}
                                                height={200}
                                                colors={vulnerabilityByAgeColors} 
                                                hideLabel={true}
                                                data={[
                                                    {
                                                        name: "0-7 days",
                                                        value: genVAOverallData?.data?.vulnerabilityByAge['0-7days'],
                                                    },
                                                    {
                                                        name: "8-30 days",
                                                        value: genVAOverallData?.data?.vulnerabilityByAge['8-30days'],
                                                    },
                                                    {
                                                        name: "31-60 days",
                                                        value: genVAOverallData?.data?.vulnerabilityByAge['31-60days'],
                                                    },
                                                    {
                                                        name: "61-90 days",
                                                        value: genVAOverallData?.data?.vulnerabilityByAge['61-90days'],
                                                    },
                                                    {
                                                        name: "90+ days",
                                                        value: genVAOverallData?.data?.vulnerabilityByAge['above90days'],
                                                    },
                                                ] || []} title={"Confirmed Vulnerabilities by Age"}
                                                subText="X - Day Range  Y - No. of Vulnerabilities"
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 gap-x-3 mt-5">
                                        <div className='xl:col-span-5 lg:col-span-5 md:col-span-5 sm:col-span-5 box-shadow border-radius-10 mr-4'>
                                            <SimpleBarChart 
                                                className={"p-0 !pt-0 !pb-0 mb-4 h-auto"}
                                                height={200}
                                                colors={vaSeverityColors} 
                                                hideLabel={true}
                                                data={[
                                                    {
                                                        name: "Urgent",
                                                        value: genVAOverallData?.data?.meanTimeToRemediate?.urgent,
                                                    },
                                                    {
                                                        name: "Critical",
                                                        value: genVAOverallData?.data?.meanTimeToRemediate?.critical,
                                                    },
                                                    {
                                                        name: "Serious",
                                                        value: genVAOverallData?.data?.meanTimeToRemediate?.serious,
                                                    },
                                                    {
                                                        name: "Medium",
                                                        value: genVAOverallData?.data?.meanTimeToRemediate?.medium,
                                                    },
                                                    {
                                                        name: "Minimum",
                                                        value: genVAOverallData?.data?.meanTimeToRemediate?.minimum,
                                                    },
                                                ] || []} title={"Mean Time to Remediate(MTTR)"}
                                            />
                                        </div>
                                        <div className='xl:col-span-7 lg:col-span-7 md:col-span-7 sm:col-span-7 box-shadow border-radius-10'>
                                            <LineChartWidget
                                                data={genVAOverallData?.data?.confirmed_vulnerability_trend}
                                                lines={qualysSeverityBreakDownLines}
                                                title={"Confirmed Vulnerability Trends"}
                                                ticks={['Q1', 'Q2', 'Q3', 'Q4']}
                                                className={"!m-0"}
                                            />
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
            }
        </>
    )
}

export default OverallVADashboard;
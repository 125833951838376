import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Paper from "../Dashboard/Paper";
import {ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, Legend, Line, Text} from "recharts";
import Typography from "../Typography";
import {areEqualProps} from "../../actions/commonActions";


const LineChartWidget = props => {
    const {title, data = [], lines = [], ticks = [], className, statement} = props
    const renderLegend = (props) => {
        const {payload} = props;
        return (
            <ul className={"text-center"}>
                {
                    payload.map((entry, index) => (
                        <li key={`item-${index}`} className={"inline-block mr-2.5 capitalize"}>
                            <div className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5"
                                 style={{background: entry.color}}></div>
                            <Typography variant={"caption"}
                                        className={"inline-block"}>{entry.value}</Typography>
                        </li>
                    ))
                }
            </ul>
        );
    }
    const renderTooltip = (props) => {
        const {payload, active} = props
        if (active && payload && payload.length) {
            return (
                <div className={"bg-white rounded-[10px] shadow-dialog p-2.5"}>
                    <Typography variant={"body1"}>{payload?.[0]?.payload?.name}</Typography>
                    {
                        payload?.map((item, index) => (
                            <div key={index} className={"py-0.5"}>
                                <Typography variant={"subtitle2"} style={{color: item.color}}
                                            className={"capitalize"}>{item.name}: {item.value}</Typography>
                            </div>
                        ))
                    }
                </div>
            )
        }
        return null
    }
    const isDataExists = !!lines.filter(line => data?.findIndex(el => el[line?.name] > 0) !== -1)?.length
    return (
        <Paper className={`px-5 ${className}`}>
            <Typography variant={"subtitle1"} className={"text-headings"}>{title}</Typography>
            <Typography variant={"subtitle1"} className={"text-primary-500 mt-4"}>{statement}</Typography>
            <div className={"h-[200px] mt-4"}>
                <ResponsiveContainer width={"100%"} height={"100%"}>
                    <LineChart
                        height={'100%'}
                        width={'100%'}
                        data={data}
                    >
                        {
                            !isDataExists &&
                            <text textAnchor={"middle"} x={"50%"} y={"30%"} className={"fill-body"}>-No Data to Display-</text>
                        }
                        {/*<CartesianGrid strokeDasharray="3 3" />*/}
                        <XAxis dataKey="displayKey" tickLine={false}/>
                        <YAxis width={40} tickLine={false} allowDecimals={false} />
                        <Tooltip content={renderTooltip}/>
                        <Legend content={renderLegend}/>
                        {
                            lines?.map((item, index) => {
                                return (
                                    <Line key={index} type="monotone" dataKey={item.name} dot={false} strokeWidth={1.8}
                                          stroke={item.color}/>
                                )
                            })
                        }
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </Paper>
    );
};

LineChartWidget.propTypes = {
    title: PropTypes.string,
    statement: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    data: PropTypes.array,
    lines: PropTypes.array,
    ticks: PropTypes.array,
    colors: PropTypes.array,
};

export default memo(LineChartWidget, areEqualProps);

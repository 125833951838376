import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import {
    ALL_BLOCKERS, ASSET_USER_STORIES,
    ASSETS,
    CUSTOMER_DETAILS,
    EXISTING_ASSETS,
    LIST_ALL_PENTEST_ASSETS,
    LIST_ALL_PENTEST_ASSETS_REVIEW,
    LIST_CUSTOMER_TEAM_BY_ROLE, LIST_SIEMBA_ASSETS,
    NEW_ASSETS, PENTEST_STATUS, BANNER_DETAILS, PRE_REQUISITES, ASSET_MANAGEMENT, FINDINGS, ALL_FINDINGS, FINDINGS_STATS, IMPORT_ASSETS, VULNERABILITY_ASSESSMENT, VULNERABILITY_LIST_DATA, VULNERABILITY_INSIGHTS, YOUR_ASSETS,
    LIST_EASM_MONITORING,LIST_EASM,
    ASSETS_DETAILS,
    ASSET_BANNER_COUNT,
    GEN_VA_OVERALL,
    GEN_VA_OVERALL_INSIGHT
} from "./utils/constants";
import axios from "axios";
import { paginationAttr } from "../actions/commonActions";
import Storage from "../utils/Storage";

//queries
export const useExistingAssets = (pentest_id, options) => {
    return useQuery([EXISTING_ASSETS, pentest_id], async () => {
        let res = await axios.get(`/pentest/asset/fetch_existing_assets?pentest_id=${pentest_id}`)
        return res.data;
    }, options)
}

export const useListAllCompanyAssets = (company_id, options) => {
    return useQuery([EXISTING_ASSETS, company_id], async () => {
        let res = await axios.get(`/pentest/asset/list_all_company_assets?company_id=${company_id}`)
        return res.data;
    }, options)
}

export const useNewAssets = (pentest_id, options) => {
    return useQuery([NEW_ASSETS, pentest_id], async () => {
        let res = await axios.get(`/pentest/asset/${pentest_id}/list_new_assets`)
        return res.data;
    }, options)
}

export const useAssetBannerCount = (options) => {
    const { company_id = null, user_id = null } = options;
    return useQuery(
        [ASSET_BANNER_COUNT, { company_id, user_id }],
        async () => {
            if (!company_id) {
                return null;
            }
            const res = await axios.get(`/assets/count${paginationAttr({ company_id, user_id })}`);            
            return res.data;
        },
        options
    );
};


export const useListAllAssets = (pentest_id, options) => {
    return useQuery([LIST_ALL_PENTEST_ASSETS, pentest_id], async () => {
        let res = await axios.get(`/pentest/asset/${pentest_id}/fetch_all_pentest_assets`)
        return res.data;
    }, options)
}
export const useListAllPentestAssets = (pentest_id, options) => {
    return useQuery([LIST_ALL_PENTEST_ASSETS_REVIEW, pentest_id], async () => {
        let res = await axios.get(`/pentest/asset/${pentest_id}/list_all_pentest_assets`)
        return res.data;
    }, options)
}

export const useListSiembaAssets = (user_id, options = {}) => {
    const {
        search = null,
        pageSize = 10,
    } = options
    return useInfiniteQuery([LIST_SIEMBA_ASSETS, { pageSize, search, user_id }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`/siemba/asset/list${paginationAttr({ page: pageParam, pageSize, search, user_id })}`)
        return res.data
    }, {
        getNextPageParam: (lastPage) => {
            return lastPage.nextPage ?? undefined
        },
        staleTime: 5 * 60 * 1000,
        keepPreviousData: true
    })
}

export const useAssetById = (asset_id) => {
    return useQuery([ASSETS, asset_id], async () => {
        let res = await axios.get(`/pentest/asset/pentest_asset/${asset_id}`)
        return res.data;
    }, {
        enabled: !!asset_id
    })
}

export const useLatestPentestAssetByAssetId = (asset_id) => {
    return useQuery([ASSETS, asset_id], async () => {
        let res = await axios.get(`/pentest/asset/latest_pentest/${asset_id}`)
        return res.data;
    }, {
        enabled: !!asset_id
    })
}

export const useMonitoringEnable = (asset_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.put(`/easm/domain/${asset_id}/monitoring`, data)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(LIST_EASM_MONITORING)
        }
    })
}

export const useVulnerabilityAssessmentById = ({ id: assetId, search, tableFilters }, refreshCache = false) => {
    return useQuery(
        [VULNERABILITY_ASSESSMENT, assetId, refreshCache],
        async () => {
            let res = await axios.get(`/pentest/asset/vulnerability-assessment/${paginationAttr({
                assetId,
            })}${refreshCache ? '&cache=1' : ''}`);
            return res.data;
        },
        {
            enabled: !!assetId,
        }
    );
};

export const useVulnerabilityListById = (options) => {
    const {
        assetId,
        selectedAssetIds,
        companyId,
        tableSearch: search,
        sortBy = [],
        ip,
        enabled = true,
        pci_vulnerabilities,
        severity,
        status,
        type,
        RTI,
        pageSize = 10,
    } = options;

    return useInfiniteQuery([VULNERABILITY_LIST_DATA, {
        assetId,
        selectedAssetIds,
        companyId,
        search,
        sortBy,
        ip,
        status,
        pci_vulnerabilities,
        severity,
        type,
        RTI,
        pageSize,
    }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`/pentest/asset/vulnerability-list${paginationAttr({
            page: pageParam,
            pageSize,
            assetId,
            selectedAssetIds,
            companyId,
            search,
            sortBy,
            ip,
            status,
            pci_vulnerabilities,
            severity,
            type,
            RTI
        })}`)
        return res.data;
    }, {
        enabled,
        getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
        keepPreviousData: true
    });
}

export const useVulnerabilityInsights = (assetId, refreshCache = false) => {
    return useQuery(
        [VULNERABILITY_INSIGHTS, assetId, refreshCache], // Include refreshCache in the queryKey
        async () => {
            let res = await axios.get(`/pentest/asset/qualys-insights?assetId=${assetId}${refreshCache ? '&cache=1' : ''}`);
            return res.data;
        },
        {
            enabled: !!assetId,
        }
    );
};

export const useUserStories = (asset_id) => {
    return useQuery([ASSET_USER_STORIES, asset_id], async () => {
        let res = await axios.get(`/pentest/asset/${asset_id}/user_stories`)
        return res.data
    }, {
        enabled: !!asset_id
    })
}

export const useGenVAOverall = (options) => {
    return useQuery([GEN_VA_OVERALL], async () => {
        let res = await axios.get(`/pentest/asset/gen-va-overall`);
        return res.data;
    }, options);
};

export const useGenVAOverallInsight = (options) => {
    return useQuery([GEN_VA_OVERALL_INSIGHT], async () => {
        let res = await axios.get(`/pentest/asset/gen-va-overall-insights`);
        return res.data;
    }, options);
};

//mutation
export const useCreateNewAsset = (options) => {
    return useMutation(async (data) => {
        let res = await axios.post(`/pentest/asset/create`, data)
        return res.data;
    }, options)
}

export const useCreateNewAssetInAssetManagement = (options) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.post(`/assetManagement/createAssets`, data)
        return res.data;
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(BANNER_DETAILS)
        },
        ...options
    })
}

export const useUpdateAssetInAssetManagement = (options) => {
    return useMutation(async (data) => {
        let res = await axios.post(`/assetManagement/updateAsset`, data)
        return res.data;
    }, options)
}

export const useGetAssetsInAssetManagement = (assetIds = null) => {

    return useQuery([ASSETS_DETAILS, { assetIds }], async () => {
        let res = await axios.get(`assetManagement/assets${(assetIds && `?assetIds=${assetIds}`) || ""}`)
        return res?.data
    })

}

export const useUpsertAsset = (options) => {
    
    return useMutation(async (data) => {
        
            let res = await axios.post(`/pentest/asset/upsert`, data)
        return res.data;
        
        
    }, options)
}

export const useAddExistingAssets = (options) => {
    return useMutation(async (data) => {
        let res = await axios.post(`/pentest/asset/add_existing_asset`, data)
        return res.data;
    }, options)
}

export const useDeletePentestAsset = (pentest_id, options) => {
    const queryClient = useQueryClient()
    return useMutation(async (asset_id) => {
        let res = await axios.put(`pentest/asset/delete_pentest_asset/${asset_id}`, { pentest_id })
        return res.data;
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(LIST_ALL_PENTEST_ASSETS_REVIEW)
            await queryClient.invalidateQueries(LIST_CUSTOMER_TEAM_BY_ROLE)
            await queryClient.invalidateQueries(CUSTOMER_DETAILS)
        },
        ...options
    })
}

export const useEditAsset = (asset_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.put(`/pentest/asset/pentest_asset/edit/${asset_id}`, data)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([ASSETS, asset_id])
        }
    })
}

export const useEditAssetDashboard = (asset_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.put(`/pentest/asset/pentest_asset/edit_dashboard/${asset_id}`, data)
        return res.data
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ASSETS)
            queryClient.invalidateQueries(PENTEST_STATUS)
            queryClient.invalidateQueries(ALL_BLOCKERS)
            queryClient.invalidateQueries(BANNER_DETAILS)
        }
    })
}

export const useUpdateAssetById = (asset_id) => {
    const queryClient = useQueryClient();
    return useMutation(async (data) => {
        let res = await axios.put(`/pentest/asset/update/${asset_id}`, data)
        return res.data
    }, {
        onSuccess: async () => {
            queryClient.invalidateQueries(LIST_EASM)
            queryClient.invalidateQueries(ASSETS)
            queryClient.invalidateQueries(YOUR_ASSETS)
            queryClient.invalidateQueries(VULNERABILITY_ASSESSMENT)
            queryClient.invalidateQueries(LIST_EASM_MONITORING)
        }
    })
}

export const useUpdateInScopeById = (asset_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.put(`/easm/subdomain/${asset_id}/inscope`, data);
        return res.data
    }, {
        onSuccess: async () => {
            queryClient.invalidateQueries(LIST_EASM)
            queryClient.invalidateQueries(ASSETS)
            queryClient.invalidateQueries(YOUR_ASSETS)
            queryClient.invalidateQueries(VULNERABILITY_ASSESSMENT)
            queryClient.invalidateQueries(LIST_EASM_MONITORING)
        }
    })
}

export const useCreateUserStories = (asset_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.post(`/pentest/asset/${asset_id}/user_stories/create_or_update/`, data)
        return res.data
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ASSETS)
            queryClient.invalidateQueries(ASSET_USER_STORIES)
        }
    })
}


export const useDeleteLatestStatus = () => {
    const queryClient = useQueryClient()
    return useMutation(async (statusId) => {
        let res = await axios.put(`/pentest/latest_status/delete/${statusId}`)
        return res.data
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ASSETS)
            queryClient.invalidateQueries(PENTEST_STATUS)
            queryClient.invalidateQueries(ALL_BLOCKERS)
            queryClient.invalidateQueries(BANNER_DETAILS)
        }
    })
}

export const useCreateLimitations = (asset_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.post(`/pentest/asset/${asset_id}/limitations/create_or_update/`, data)
        return res.data
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ASSETS)
        }
    })
}

export const useUpdateAssetUserRoles = (pentest_asset_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.put(`/pentest/asset/pentest_asset/update_user_role/${pentest_asset_id}`, data)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PRE_REQUISITES)
        }
    })
}

export const useImportAssets = (options) => {
    const queryClient = useQueryClient();
    return useMutation(
        async (data) => {

            let res = await axios.post(`/assetManagement/importAssets`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the content type as multipart/form-data
                },
            });
            return res.data;
        },
        options,
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(IMPORT_ASSETS);
            },
        }
    );
};
export const useDecommisionAssets = (updateSelectedRows) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        const payload = {
            data: data.justificationData,
            type: "decommission"
        }

        let res = await axios.delete(`/assetManagement/assets`, {
            data: payload
        });
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ASSET_MANAGEMENT)
            await queryClient.invalidateQueries(FINDINGS)
            await queryClient.invalidateQueries(ALL_FINDINGS)
            await queryClient.invalidateQueries(FINDINGS_STATS)
            await queryClient.invalidateQueries(LIST_SIEMBA_ASSETS)
        },
    })
}

export const useDeleteAssets = () => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        const payload = {
            data: data.justificationData,
            type: "delete"
        }
        let res = await axios.delete(`/assetManagement/assets`, {
            data: payload
        });
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ASSET_MANAGEMENT)
            await queryClient.invalidateQueries(FINDINGS)
            await queryClient.invalidateQueries(ALL_FINDINGS)
            await queryClient.invalidateQueries(FINDINGS_STATS)
            await queryClient.invalidateQueries(LIST_SIEMBA_ASSETS)
        },
    })
}

export const useRecommisionAssets = (updateSelectedRows) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        const payload = {
            data: data.justificationData,
            type: "recommission"
        }

        let res = await axios.delete(`/assetManagement/assets`, {
            data: payload
        });
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ASSET_MANAGEMENT)
            await queryClient.invalidateQueries(FINDINGS)
            await queryClient.invalidateQueries(ALL_FINDINGS)
            await queryClient.invalidateQueries(FINDINGS_STATS)
            await queryClient.invalidateQueries(LIST_SIEMBA_ASSETS)
        },
    })
}

import React from 'react';

const StatusUpdateIcon = ({className}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className={`inline-block ${className}`}
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.3365 19.6143C8.9207 19.3032 7.61973 18.6036 6.57943 17.5942C5.5392 16.5849 4.80072 15.3057 4.44689 13.8999C4.09306 12.4942 4.13791 11.0178 4.57653 9.63616C4.64188 9.43139 4.59314 9.20732 4.44849 9.04834C4.30385 8.88935 4.08541 8.81958 3.87554 8.86539C3.66554 8.91106 3.49586 9.06534 3.43051 9.27013C2.91784 10.8675 2.86185 12.5764 3.269 14.2038C3.676 15.8315 4.52992 17.3129 5.73421 18.481C6.9385 19.649 8.44533 20.4571 10.0844 20.8142H10.2104C10.4248 20.8367 10.6348 20.7431 10.7615 20.5687C10.8882 20.3943 10.9122 20.1657 10.8245 19.9687C10.7369 19.7718 10.5507 19.6367 10.3365 19.6142L10.3365 19.6143Z"
                fill="#228EBC"/>
            <path
                d="M5.81415 6.95425C5.98344 6.95385 6.14469 6.88193 6.25812 6.7563C7.64065 5.23179 9.57176 4.31852 11.6272 4.21727C13.6827 4.11603 15.6942 4.8351 17.2199 6.2163C17.3328 6.34688 17.4966 6.42269 17.6692 6.42403C17.8419 6.42537 18.0069 6.35224 18.1218 6.2234C18.2367 6.09456 18.2905 5.92233 18.2695 5.75091C18.2485 5.57961 18.1546 5.42546 18.0119 5.32823C16.2536 3.73141 13.9333 2.89755 11.5608 3.01007C9.18823 3.12257 6.95723 4.17219 5.35815 5.92823C5.24177 6.04315 5.17722 6.20064 5.17976 6.36418C5.18217 6.5277 5.25128 6.6832 5.37101 6.79464C5.49074 6.90606 5.65079 6.96365 5.81418 6.95428L5.81415 6.95425Z"
                fill="#228EBC"/>
            <path
                d="M17.932 8.67012C18.13 9.01647 18.4584 9.26933 18.8439 9.37205C18.9638 9.40098 19.0868 9.41504 19.21 9.4141C19.6532 9.41316 20.0733 9.21642 20.3576 8.87636C20.642 8.53645 20.7614 8.08807 20.6839 7.65171C20.6063 7.21526 20.3398 6.83554 19.9557 6.6143C19.5717 6.39305 19.1094 6.353 18.693 6.50488C18.2766 6.65675 17.9488 6.98501 17.7973 7.40152C17.6459 7.81802 17.6864 8.28023 17.908 8.66405L17.932 8.67012ZM19.0841 7.66218L19.0839 7.66205C19.1216 7.63634 19.1647 7.61986 19.21 7.61411H19.2879C19.4205 7.66861 19.496 7.8091 19.4683 7.94973C19.4406 8.09022 19.3173 8.19161 19.1739 8.19161C19.0306 8.19161 18.9074 8.09022 18.8797 7.94973C18.8519 7.8091 18.9274 7.66862 19.06 7.61411L19.0841 7.66218Z"
                fill="#228EBC"/>
            <path
                d="M20.8429 10.3082C20.802 10.0939 20.6502 9.91754 20.444 9.84565C20.2381 9.77373 20.0094 9.81725 19.844 9.95962C19.6788 10.1021 19.602 10.3219 19.6429 10.5363C19.7461 11.0217 19.8024 11.516 19.8108 12.0122C19.7996 13.7853 19.1876 15.5021 18.0751 16.8828C16.9626 18.2635 15.415 19.2263 13.6848 19.6143C13.4705 19.6368 13.2843 19.7719 13.1968 19.9688C13.109 20.1658 13.133 20.3944 13.2597 20.5688C13.3864 20.7432 13.5964 20.8368 13.8108 20.8143H13.9368C16.2332 20.3192 18.2432 18.9419 19.534 16.9791C20.8246 15.0163 21.2925 12.6248 20.837 10.3205L20.8429 10.3082Z"
                fill="#228EBC"/>
            <path
                d="M18.6099 12.012C18.6097 11.3128 18.4983 10.6182 18.2799 9.95404C18.2124 9.75033 18.0414 9.59819 17.8313 9.55492C17.6212 9.51153 17.4039 9.58358 17.2613 9.74389C17.1186 9.9042 17.0724 10.1284 17.1399 10.332C17.7075 12.0436 17.3833 13.9254 16.2758 15.3483C15.1682 16.7713 13.4236 17.5476 11.625 17.4175C9.82645 17.2876 8.21152 16.2684 7.3204 14.7009C6.42897 13.1334 6.37888 11.2243 7.18674 9.61223C7.99472 8.00012 9.55393 6.89763 11.3432 6.67343C13.1325 6.44923 14.9154 7.13293 16.0962 8.49592C16.1988 8.62169 16.3479 8.70057 16.5097 8.71463C16.6715 8.72856 16.8319 8.67646 16.9545 8.57012C17.0772 8.46365 17.1515 8.31218 17.1605 8.15012C17.1694 7.98793 17.1124 7.82909 17.0023 7.70989C15.9895 6.53897 14.5975 5.76123 13.0694 5.5128C11.5414 5.26436 9.97467 5.56101 8.64308 6.35077C7.31156 7.14054 6.30013 8.37336 5.78543 9.83317C5.27087 11.2933 5.28589 12.8878 5.82789 14.338C6.36991 15.788 7.40448 17.0014 8.75061 17.766C10.0967 18.5306 11.6688 18.7977 13.192 18.5204C14.715 18.2433 16.0922 17.4395 17.0827 16.2497C18.0733 15.0599 18.6139 13.5599 18.6103 12.0116L18.6099 12.012Z"
                fill="#228EBC"/>
        </svg>
    );
};

export default StatusUpdateIcon;

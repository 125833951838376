import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import reactStringReplace from 'react-string-replace';
import { setCTEMKeyInsightsModal, setKeyInsightMainModal } from '../../redux/reducers/dashboard';
import AISOIcon from '../SvgIcons/AISOIcon';
import MainLoader from '../Loader/MainLoader';
import Typography from '../Typography';

const KeyInsights = ({
  className,
  title,
  containerClass,
  isFetching,
  data,
  companyId,
  detailedData,
  role,
  type,
  assetId
}) => {
  const keyInsights = data?.keyInsights;

  const dispatch = useDispatch();
  const openModal = (data = {}) => {
    const { insightId, title, description, totalCount, service } = data;
    dispatch(
      setKeyInsightMainModal({
        open: true,
        insightId,
        title,
        description: description,
        count: totalCount,
        companyId,
        detailedData,
        role,
        service,
        type,
        assetId
      })
    );
  };

  const RightArrow = () => {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    return (
      <>
        {!isLastItemVisible && (
          <div className={`${styles.moreAction} ${styles.right}`}>
            <div className={styles.arrow}>
              <ChevronRightIcon onClick={() => scrollNext()} />
            </div>
          </div>
        )}
      </>
    );
  };
  const LeftArrow = () => {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (
      <>
        {!isFirstItemVisible && (
          <div className={`${styles.moreAction} ${styles.left}`}>
            <div className={styles.arrow}>
              <ChevronLeftIcon onClick={() => scrollPrev()} />
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <Typography variant={'h7'} className={'text-headings flex flex-align-item-center'}>
        {title ? (
          title
        ) : (
          <>
            <span>
              <AISOIcon />
            </span>
            <span className="insight-text">Insights</span>
          </>
        )}
      </Typography>
      <div key={isFetching} className={`${styles.keyInsights} ${className} ctem-insights`}>
        {isFetching && <MainLoader />}
        <div className={`${styles.container} ${containerClass}`}>
          {keyInsights?.length > 0 && (
            <ScrollMenu
              RightArrow={RightArrow}
              LeftArrow={LeftArrow}
              scrollContainerClassName={'no-scrollbar ctem-insight-scrollbar'}
            >
              {keyInsights?.map((item, index) => {
                return (
                  <Block
                    key={index}
                    itemId={index}
                    count={item?.totalCount}
                    percent={item?.percent}
                    title={item?.title}
                    service={item?.service}
                    description={item?.description}
                    priority={item?.priority}
                    type={item?.type}
                    onKnowMore={openModal}
                    item={item}
                    // insightTag={'Threat Insight'}
                    // stageTag={'Mobilization Stage'}
                  />
                );
              })}
            </ScrollMenu>
          )}
          {keyInsights?.length === 0 && (
            <div className={`mr-3 bg-secondary-50 items-center text-center`}>
              <Typography variant={'body1'} className={'py-28 w-full text-body font-semibold'}>
                {'No Insights Yet!'}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const Block = ({
  count,
  title,
  description,
  priority,
  item,
  onKnowMore,
  insightTag,
  stageTag,
  service,
}) => {
  return (
    <div onClick={() => onKnowMore(item)} className={`${styles.block} cursor-pointer relative flex flex-col justify-between bg-secondary-50 hover:bg-[#E0FBFA] transition duration-50`}>
      <div>
        <div className={`absolute priority ${priority}`}>{`${priority} priority`}</div>
        <div className="gap-2.5">
          <div className="flex title-sec mt-2">
            <div className="flex w-[45px] h-[40px] flex-align-center flex-align-item-center keyInsight-count text-secondary-700 mr-2">
              {count}
            </div>
            <Typography variant={'subtitle2'} className="flex flex-align-item-center flex-[100%]">
              {reactStringReplace(title, '{{COUNT}}', (match, i) => (
                <span></span>
              ))}
            </Typography>
          </div>
        </div>
        <Typography variant={'body2'} className={'mt-3 line-clamp-4'}>
          {description.replace('{{COUNT}}', count.toString())}
        </Typography>
        {(insightTag || stageTag) && (
          <div className="flex space-x-2 mt-2">
            {insightTag && (
              <div className="rounded-[20px] border border-[#7E8299] bg-white px-[6px] py-[2px] inline-block text-[10px] font-semibold">
                {insightTag}
              </div>
            )}
            {stageTag && (
              <div className="rounded-[20px] border border-[#7E8299] bg-white px-[6px] py-[2px] inline-block text-[10px] font-semibold">
                {stageTag}
              </div>
            )}
          </div>
        )}
        {service && (
          <div className="rounded-[20px] border border-[#7E8299] bg-white px-[6px] py-[2px] inline-block text-[10px] font-semibold uppercase mt-2">
            {service}
          </div>
        )}
      </div>
      <div>
        <Typography variant={'body2'} className={'mt-3 font-bold line-clamp-4'}>
          KNOW MORE {" >"}
        </Typography>
      </div>
    </div>
  );
};

KeyInsights.propTypes = {};

export default memo(KeyInsights);

// import "./styles.css";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  Text
} from "recharts";

const CustomXAxisTick = ({ x, y, payload }) => {
    if (payload && payload.value) {
      return (
        <Text
            fontSize={"10px"}
            width={"12px"}
            height={"15px"}
            x={x} 
            y={y} 
            textAnchor="middle" 
            verticalAnchor="start"
        >{payload.value}</Text>
      );
    }
    return null;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-graph-tooltip">
        <p className="label text-camel-case">{`${payload[0].payload.name}: ${payload[0].payload.value}`}</p>
      </div>
    );
  }

  return null;
};

export default function BarChartWidget(props) {
  const { label, height, data, colors, subText, action, type } = props;

  const handleBarClick = (data) => {
    if (action) {
        action(type, data?.name);
    }
  };

  return (
    <div className="bar-chart">
        <label>{label}</label>
        <p>{subText}</p>
        <ResponsiveContainer width="100%" height={height}>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
                onClick={(data) => {
                    if (data && data.activePayload && data.activePayload.length > 0) {
                      if (data.activePayload[0].payload?.value) {
                        handleBarClick(data.activePayload[0].payload)
                      }
                    }
                }}
            >
                <CartesianGrid stroke="#cccccc9e" strokeDasharray="3 3" opacity={0.8} />
                <XAxis 
                    dataKey="name"
                    interval={0}
                    tick={CustomXAxisTick}
                />
                <YAxis allowDecimals={false} />
                <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip />} />
                <Bar 
                  className={`bar-chart`}
                  dataKey="value" fill="#8884d8"
                  barSize={27}
                  radius={[200, 200, 0, 0]}
                >
                    {data.map((entry, index) => (
                        <Cell 
                          key={`cell-${index}`} 
                          fill={colors[index % 20]} 
                          className={entry.value > 0 && action ? 'cursor-pointer' : ''}
                        />
                    ))}
                </Bar>
            </BarChart> 
        </ResponsiveContainer>
    </div>
  );
}

export const severityBreakDownLines = [
    {
        name: "critical", color: 'var(--functional-red-900)'
    },
    {
        name: "high", color: 'var(--functional-red-700)'
    },
    {
        name: "medium", color: 'var(--functional-orange)'
    },
    {
        name: "low", color: 'var(--functional-yellow)'
    },
    {
        name: "informational", color: 'var(--functional-blue-50)'
    },
]

export const cloudSeverityBreakDownLines = [
    {
        name: "critical", color: 'var(--functional-red-900)'
    },
    {
        name: "high", color: 'var(--functional-red-700)'
    },
    {
        name: "medium", color: 'var(--functional-orange)'
    },
    {
        name: "low", color: 'var(--functional-yellow)'
    }
]

export const qualysSeverityBreakDownLines = [
    {
        name: "urgent", color: '#9A2335'
    },
    {
        name: "critical", color: '#CC394F'
    },
    {
        name: "serious", color: '#FE7754'
    },
    {
        name: "medium", color: '#FFE765'
    },
    {
        name: "minimum", color: '#CFE2F3'
    },
]

export const statusBreakDownLines = [{
    name: "open", color: 'var(--functional-red-900)'
},{
    name: "retest failed", color: 'var(--functional-red-700)'
}, {
    name: "retest ready", color: 'var(--functional-blue)'
}, {
    name: "risk accepted", color: 'var(--functional-orange)'
}, {
    name: "risk transferred", color: 'var(--functional-yellow)'
}, 
]

export const cloudStatusBreakDownLines = [
    {
    name: "retest failed", color: 'var(--functional-red-700)'
}, {
    name: "retest ready", color: 'var(--functional-blue)'
}, 
{
    name: "passed", color: 'var(--success-green)'
},
{
    name: "failed", color: 'var(--functional-red-900)'
},
// {
//     name: "potential", color: 'var(--functional-blue-600)'
// },
{
    name: "info", color: 'var(--functional-purple)'
},
]



import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    addUsersModal: {
        open: false,
    },
    addNewAssetsModal: {
        open: false,
        company_id: null
    },
    editAssetsModal: {
        open: false,
        company_id: null,
        asset: null
    },
    decommissionAssetsModal: {
        open: false,
        selectedRows: null,
    },
    deleteAssetsModal: {
        open: false,
        selectedRows: null
    },
    recommissionAssetsModal: {
        open: false,
        selectedRows: null
    },
    addFindingsModal: {
        open: false,
        asset_id: null,
        finding_id: null,
        progress: null
    },
    newPentestModal: {
        open: false,
        pentest_id: null,
        progress: null,
        latest_progress: null
    },
    newEASMModal: {
        open: false
    },
    addBlockerModal: {
        open: false,
        pentest_asset_id: null,
        id: null,
        pentestAsset: null,
        pentest_id: null,
        generalBlocker: false,
    },
    updatePentestLandscape: false,
    explore: {
        dashboard: null,
        id: null
    },
    keyInsightsModal: {
        open: false,
        id: null
    },
    keyInsightMainModal:{
        open: false,
        id: null,
        insightId: null
    },
    qualysKeyInsightsModal: {
        open: false,
        id: null
    },
    ctemKeyInsightsModal: {
        open: false,
        id: null,
    },
    easmKeyInsightsModal: {
        open: false,
        id: null,
    },
    domainKeyInsightsModal: {
        open: false,
        id: null,
    },
    domainSpecificKeyInsightsModal: {
        open: false,
        id: null,
    },
    prioritizedFindingModal: {
        open: false,
        id: null,
    },
    assetListModal: {
        open: false,
        id: null,
    },
    preRequisitesData: {},
    preRequisitesMetaData: {},
    preRequisitesEditCategory: null,
    preRequisitesAdditionalQueries: [],
    reportModal: {
        open: false,
        pentest_asset_id: null,
        report_detail: {}
    },
    customFindingsReportModal: {
        open: false,
    },
    genptReportModal: {
        open: false,
        session_id: null,
    },
    inviteCustomerModal: {
        open: false,
    },
    importAssetsModal: {
        open: false,
    },
    importGenptTestsModal: {
        open: false,
    },
    severityChangeModal: {
        open: false,
        severity_change:{},
        justification:null,
        is_justification_submitted: false,
        edit_mode: false,
        finding_id: null,
        asset_id: null
    },
    subscribeModal: {
        open: false
    },
    setupVAModal: {
        open: false
    },
    genptSubscribeModal: {
        open: false,
        from: 'menu'
    },
    easmSubscribeModal: {
        open: false
    },
    GPTSession: {
        session_id: null,
        asset_type: null,
        target_url: null,
    },
    GPTTestConfigs: {
        vulnerability: {
            rate_limit: 80,
            concurrency: 10,
            timeout: 30,
            retries: 2,
        },
        validation: {
            timeout: 30,
            retries: 2,
        },
    },
    spfModal: {
        open: false,
        id: null,
    },
    dkimModal: {
        open: false,
        id: null,
    },
    dmarcModal: {
        open: false,
        id: null,
    },
}
export const authSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setAddUsersModal: (state, action) => {
            return {
                ...state,
                addUsersModal: {
                    ...state.addUsersModal,
                    ...action.payload
                }
            }
        },
        setAddNewAssetsModal: (state, action) => {
            return {
                ...state,
                addNewAssetsModal: {
                    ...state.addNewAssetsModal,
                    ...action.payload
                }
            }
        },
        setEditAssetsModal: (state, action) => {
            return {
                ...state,
                editAssetsModal: {
                    ...state.editAssetsModal,
                    ...action.payload,
                }
            }
        },
        setDecommissionAssetsModal: (state, action) => {
            return {
                ...state,
                decommissionAssetsModal: {
                    ...state.decommissionAssetsModal,
                    ...action.payload
                }
            }
        },
        setDeleteAssetsModal: (state, action) => {
            return {
                ...state,
                deleteAssetsModal: {
                    ...state.deleteAssetsModal,
                    ...action.payload
                }
            }
        },
        setRecommissionAssetsModal: (state, action) => {
            return {
                ...state,
                recommissionAssetsModal: {
                    ...state.recommissionAssetsModal,
                    ...action.payload
                }
            }
        },
        setAddFindingsModal: (state, action) => {
            return {
                ...state,
                addFindingsModal: {
                    ...state.addFindingsModal,
                    ...action.payload
                }
            }
        },
        setNewPentestModal: (state, action) => {
            return {
                ...state,
                newPentestModal: {
                    ...state.newPentestModal,
                    ...action.payload
                }
            }
        },
        setNewEASMModal: (state, action) => {
            return {
                ...state,
                newEASMModal: {
                    ...state.newEASMModal,
                    ...action.payload
                }
            }
        },
        setAddBlockerModal: (state, action) => {
            return {
                ...state,
                addBlockerModal: {
                    ...state.addBlockerModal,
                    ...action.payload
                }
            }
        },
        setKeyInsightsModal: (state, action) => {
            return {
                ...state,
                keyInsightsModal: {
                    ...state.keyInsightsModal,
                    ...action.payload
                }
            }
        },
        setQualysKeyInsightsModal: (state, action) => {
            return {
                ...state,
                qualysKeyInsightsModal: {
                    ...state.qualysKeyInsightsModal,
                    ...action.payload
                }
            }
        },
        setGenptReportModal: (state, action) => {
            return {
                ...state,
                genptReportModal: {
                    ...state.genptReportModal,
                    ...action.payload
                }
            }
        },
        
        setCTEMKeyInsightsModal:(state, action) => {
            return {
                ...state,
                ctemKeyInsightsModal: {
                    ...state.ctemKeyInsightsModal,
                    ...action.payload
                }
            }
        },
        
        setKeyInsightMainModal:(state, action) => {
            return {
                ...state,
                keyInsightMainModal: {
                    ...state.keyInsightMainModal,
                    ...action.payload
                }
            }
        },
        
        setEASMKeyInsightsModal:(state, action) => {
            return {
                ...state,
                easmKeyInsightsModal: {
                    ...state.easmKeyInsightsModal,
                    ...action.payload
                }
            }
        },
        setDomainKeyInsightsModal:(state, action) => {
            return {
                ...state,
                domainKeyInsightsModal: {
                    ...state.domainKeyInsightsModal,
                    ...action.payload
                }
            }
        },
        setDomainSpecificKeyInsightsModal:(state, action) => {
            return {
                ...state,
                domainSpecificKeyInsightsModal: {
                    ...state.domainSpecificKeyInsightsModal,
                    ...action.payload
                }
            }
        },
        setPrioritizedFindingModal:(state, action) => {
            return {
                ...state,
                prioritizedFindingModal: {
                    ...state.prioritizedFindingModal,
                    ...action.payload
                }
            }
        },
        setAssetListModal:(state, action) => {
            return {
                ...state,
                assetListModal: {
                    ...state.assetListModal,
                    ...action.payload
                }
            }
        },
        closeUsersModal: (state, action) => {
            return {
                ...state,
                addUsersModal: initialState.addUsersModal
            }
        },
        closeNewAssetsModal: (state, action) => {
            return {
                ...state,
                addNewAssetsModal: initialState.addNewAssetsModal
            }
        },
        closeEditAssetsModal: (state, action) => {
            return {
                ...state,
                editAssetsModal: initialState.editAssetsModal
            }
        },
        closeDecommissionAssetsModal: (state, action) => {
            return {
                ...state,
                decommissionAssetsModal: initialState.decommissionAssetsModal
            }
        },
        closeDeleteAssetsModal: (state, action) => {
            return {
                ...state,
                deleteAssetsModal: initialState.deleteAssetsModal
            }
        },
        closeRecommissionAssetsModal: (state, action) => {
            return {
                ...state,
                recommissionAssetsModal: initialState.recommissionAssetsModal
            }
        },
        closeFindingsModal: (state, action) => {
            return {
                ...state,
                addFindingsModal: initialState.addFindingsModal
            }
        },
        closeKeyInsightsModal: (state) => {
            return {
                ...state,
                keyInsightsModal: initialState.keyInsightsModal
            }
        },
        closeQualysKeyInsightsModal: (state) => {
            return {
                ...state,
                qualysKeyInsightsModal: initialState.qualysKeyInsightsModal
            }
        },
        closeGenptReportModal: (state) => {
            return {
                ...state,
                genptReportModal: initialState.genptReportModal
            }
        },
        closeCTEMKeyInsightsModal: (state) => {
            return {
                ...state,
                ctemKeyInsightsModal: initialState.ctemKeyInsightsModal
            }
        },
        closeKeyInsightMainModal: (state) => {
            return {
                ...state,
                keyInsightMainModal: initialState.keyInsightMainModal
            }
        },
        closeEASMKeyInsightsModal: (state) => {
            return {
                ...state,
                easmKeyInsightsModal: initialState.easmKeyInsightsModal
            }
        },
        closeDomainKeyInsightsModal: (state) => {
            return {
                ...state,
                domainKeyInsightsModal: initialState.domainKeyInsightsModal
            }
        },
        closeDomainSpecificKeyInsightsModal: (state) => {
            return {
                ...state,
                domainSpecificKeyInsightsModal: initialState.domainSpecificKeyInsightsModal
            }
        },
        closePrioritizedFindingModal: (state) => {
            return {
                ...state,
                prioritizedFindingModal: initialState.prioritizedFindingModal
            }
        },
        closeAssetListModal: (state) => {
            return {
                ...state,
                assetListModal: initialState.assetListModal
            }
        },
        closePentestModal: (state, action) => {
            return {
                ...state,
                newPentestModal: initialState.newPentestModal
            }
        },
        closeEASMModal: (state, action) => {
            return {
                ...state,
                newEASMModal: initialState.newEASMModal
            }
        },
        closeBlockerModal: (state, action) => {
            return {
                ...state,
                addBlockerModal: initialState.addBlockerModal
            }
        },
        explore: (state, action) => {
            return {
                ...state,
                explore: {
                    ...state.explore,
                    ...action.payload
                }
            }
        },
        closeExplore: (state) => {
            return {
                ...state,
                explore: initialState.explore
            }
        },
        setUpdatePentestLandscape: (state, action) => {
            return {
                ...state,
                updatePentestLandscape: action.payload
            }
        },
        setPreRequisitesData: (state, action) => {
            return {
                ...state,
                preRequisitesData: action.payload
            }
        },
        setDashboardInputs: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        setReportModal: (state, action) => {
            return {
                ...state,
                reportModal: action.payload
            }
        },
        closeReportModal: (state, action) => {
            return {
                ...state,
                reportModal: initialState.reportModal
            }
        },
        setQualysReportModal: (state, action) => {
            return {
                ...state,
                qualysReportModal: action.payload
            }
        },
        closeQualysReportModal: (state, action) => {
            return {
                ...state,
                qualysReportModal: initialState.qualysReportModal
            }
        },
        setIPCountModal: (state, action) => {
            return {
                ...state,
                IPCountModal: action.payload
            }
        },
        closeIPCountModal:(state, action) => {
            return {
                ...state,
                IPCountModal: initialState.IPCountModal
            }
        },
        setCustomFindingsReportModal: (state, action) => {
            return {
                ...state,
                customFindingsReportModal: action.payload
            }
        },
        closeCustomFindingsReportModal: (state, action) => {
            return {
                ...state,
                customFindingsReportModal: initialState.customFindingsReportModal
            }
        },
        setInviteCustomerModal: (state, action) => {
            return {
                ...state,
                inviteCustomerModal: action.payload
            }
        },
        setSubscribeModal: (state, action) => {
            return {
                ...state,
                subscribeModal: action.payload
            }
        },
        setSetupVAModal: (state, action) => {
            return {
                ...state,
                setupVAModal: action.payload
            }
        },
        setGenptSubscribeModal: (state, action) => {
            return {
                ...state,
                genptSubscribeModal: action.payload
            }
        },
        setEasmSubscribeModal: (state, action) => {
            return {
                ...state,
                easmSubscribeModal: action.payload
            }
        },
        closeInviteCustomerModal: (state, action) => {
            return {
                ...state,
                inviteCustomerModal: initialState.inviteCustomerModal
            }
        },
        closeSubscribeModal: (state) => {
            return {
                ...state,
                subscribeModal: initialState.subscribeModal
            }
        },
        closeSetupVAModal: (state, action) => {
            return {
                ...state,
                setupVAModal: initialState.setupVAModal
            }
        },
        closeGenptSubscribeModal: (state) => {
            return {
                ...state,
                genptSubscribeModal: initialState.genptSubscribeModal
            }
        },
        closeEasmSubscribeModal: (state) => {
            return {
                ...state,
                easmSubscribeModal: initialState.easmSubscribeModal
            }
        },
        setImportAssetsModal: (state, action) => {
            return {
                ...state,
                importAssetsModal: action.payload
            }
        },
        closeImportAssetsModal: (state, action) => {
            return {
                ...state,
                importAssetsModal: initialState.importAssetsModal
            }
        },
        setGenPTImportTestsModal: (state, action) => {
            return {
                ...state,
                importGenptTestsModal: action.payload
            }
        },
        closeGenPTImportTestsModal: (state, action) => {
            return {
                ...state,
                importGenptTestsModal: initialState.importGenptTestsModal
            }
        },
        setEditCompanyModal: (state, action) => {
            return {
                ...state,
                editCompanyModal: {
                    ...state.editCompanyModal,
                    ...action.payload,
                }
            }
        },
        closeEditCompanyModal: (state, action) => {
            return {
                ...state,
                editCompanyModal: initialState.editCompanyModal
            }
        },        
        setSeverityChangeModal: (state, action) => {
            return {
                ...state,
                severityChangeModal: {
                    ...state.severityChangeModal,
                    ...action.payload
                }
            }
        },
        closeSeverityChangeModal: (state, action) => {
            return {
                ...state,
                severityChangeModal: initialState.severityChangeModal
            }
        },
        setGPTSession: (state, action) => {
            return {
                ...state,
                GPTSession: action.payload
            }
        },
        clearGPTSession: (state, action) => {
            return {
                ...state,
                GPTSession: initialState.GPTSession
            }
        },
        setGPTTestConfig: (state, action) => {
            return {
                ...state,
                GPTTestConfigs: action.payload
            }
        },
        clearGPTTestConfig: (state, action) => {
            return {
                ...state,
                GPTTestConfigs: initialState.GPTTestConfigs
            }
        },
        setSPFModal:(state, action) => {
            return {
                ...state,
                spfModal: {
                    ...state.spfModal,
                    ...action.payload
                }
            }
        },
        closeSPFModal: (state) => {
            return {
                ...state,
                spfModal: initialState.spfModal
            }
        },
        setDKIMModal:(state, action) => {
            return {
                ...state,
                dkimModal: {
                    ...state.dkimModal,
                    ...action.payload
                }
            }
        },
        closeDKIMModal: (state) => {
            return {
                ...state,
                dkimModal: initialState.dkimModal
            }
        },
        setDMARCModal:(state, action) => {
            return {
                ...state,
                dmarcModal: {
                    ...state.dmarcModal,
                    ...action.payload
                }
            }
        },
        closeDMARCModal: (state) => {
            return {
                ...state,
                dmarcModal: initialState.dmarcModal
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setDecommissionAssetsModal,
    closeDecommissionAssetsModal,
    setRecommissionAssetsModal,
    closeRecommissionAssetsModal,
    setDeleteAssetsModal,
    closeDeleteAssetsModal,
    setAddUsersModal,
    closeUsersModal,
    setAddFindingsModal,
    closeFindingsModal,
    explore,
    closeExplore,
    setNewPentestModal,
    setNewEASMModal,
    closePentestModal,
    closeEASMModal,
    setAddBlockerModal,
    closeBlockerModal,
    setUpdatePentestLandscape,
    setKeyInsightsModal,
    closeKeyInsightsModal,
    setQualysKeyInsightsModal,
    closeQualysKeyInsightsModal,
    setCTEMKeyInsightsModal,
    closeCTEMKeyInsightsModal,
    setKeyInsightMainModal,
    closeKeyInsightMainModal,
    setEASMKeyInsightsModal,
    setDomainKeyInsightsModal,
    setDomainSpecificKeyInsightsModal,
    closeDomainSpecificKeyInsightsModal,
    closeEASMKeyInsightsModal,
    setPrioritizedFindingModal,
    closePrioritizedFindingModal,
    setAssetListModal,
    closeAssetListModal,
    setPreRequisitesData,
    setDashboardInputs,
    setReportModal,
    closeReportModal,
    setQualysReportModal,
    closeQualysReportModal,
    setIPCountModal,
    closeIPCountModal,
    setCustomFindingsReportModal,
    closeCustomFindingsReportModal,
    setInviteCustomerModal,
    closeInviteCustomerModal,
    setAddNewAssetsModal,
    closeNewAssetsModal,
    setImportAssetsModal,
    closeImportAssetsModal,
    setEditAssetsModal,
    closeEditAssetsModal,
    setEditCompanyModal,
    closeEditCompanyModal,
    setSeverityChangeModal,
    closeSeverityChangeModal,
    setGPTSession,
    clearGPTSession,
    setSubscribeModal,
    closeSubscribeModal,
    setGenptSubscribeModal,
    closeGenptSubscribeModal,
    setGenPTImportTestsModal,
    closeGenPTImportTestsModal,
    setGPTTestConfig,
    clearGPTTestConfig,
    setGenptReportModal,
    closeGenptReportModal,
    setSetupVAModal,
    closeSetupVAModal,
    setSPFModal,
    closeSPFModal,
    setDKIMModal,
    closeDKIMModal,
    setDMARCModal,
    closeDMARCModal,
    closeDomainKeyInsightsModal,
    setEasmSubscribeModal,
    closeEasmSubscribeModal
} = authSlice.actions

export default authSlice.reducer

import React, { useState } from "react";
import Select, {components} from "react-select";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon/>
      </components.DropdownIndicator>
    );
  };

const ReactSelectMultiSelect = (props) => {
    const [isClearable, setIsClearable] = useState(true);
    const { name, eleRef, className, options, ValueContainer, styles, onChange, value, isMulti, isClearableCheck, placeholder, defaultValue } = props
    return (
        <Select
            menuPortalTarget={document.body} menuPosition={'fixed'}
            name={name}
            ref={eleRef}
            className={className}
            options={options}
            isMulti={isMulti}
            isClearable={isClearableCheck ? isClearable : false}
            hideSelectedOptions={false}
            components={{ ValueContainer, DropdownIndicator }}
            styles={styles}
            placeholder={placeholder}
            onChange={onChange}
            value= {value}
            defaultValue={defaultValue}
        />
    );
};

export default ReactSelectMultiSelect;

import React from "react";
import Info from "../../../../components/Info";

const OpenFindingsOverview = (props) => {
    const { type, handleFindingsRedirection, totalFindingsCount, urgent, critical, serious, medium, minimum } = props;

    return (
        <div className="open-findings-overview">
            {/* <div className="overlay cursor-pointer"></div> */}
            <div className={
                `total-findings ${totalFindingsCount && "cursor-pointer"} ${type === 'confirmed-findings' ? 'confirmed-findings' : type === 'potential-findings' ? 'potential-findings' : 'information-gathering'}`
            }
            onClick={() => totalFindingsCount && handleFindingsRedirection(type === 'confirmed-findings' ? 'confirmed' : type === 'potential-findings' ? 'potential' : 'info')}
            >                
                <div 
                    className={`count ${type === 'confirmed-findings' ? 'confirmed-red' : type === 'potential-findings' ? 'secondary-color' : 'primary-color'}`}
                >
                    {totalFindingsCount}
                </div>
                <div className="type">
                    {
                        type === 'confirmed-findings' ?
                            'Confirmed findings'
                        :
                            type === 'potential-findings' ?
                            <span>Potential Findings <Info tooltip={'Disclaimer: Vulnerability type "Potential" include vulnerabilities that can not be entirely verified. In such instances, the detection of at least one necessary condition for the vulnerability suggests its potential existence. We advise conducting thorough investigations to confirm the presence of these vulnerabilities.'} /></span>
                            :
                                'Information Gathering'
                    }
                </div>
            </div>
            <div className="findings-count-breakdown">
                <div className="content">
                    <div 
                        className={`urgent ${urgent && "cursor-pointer"}`}
                        onClick={() => urgent && handleFindingsRedirection(type === 'confirmed-findings' ? 'confirmed' : type === 'potential-findings' ? 'potential' : 'info', 'urgent')}
                    >
                        <div className="label"><span className="indicators"></span> Urgent</div>
                        <div className="value">{urgent}</div>
                    </div>
                    <div 
                        className={`critical ${critical && "cursor-pointer"}`}
                        onClick={() => critical && handleFindingsRedirection(type === 'confirmed-findings' ? 'confirmed' : type === 'potential-findings' ? 'potential' : 'info', 'critical')}
                    >
                        <div className="label"><span className="indicators"></span> Critical</div>
                        <div className="value">{critical}</div>
                    </div>
                    <div 
                        className={`serious ${serious && "cursor-pointer"}`}
                        onClick={() => serious && handleFindingsRedirection(type === 'confirmed-findings' ? 'confirmed' : type === 'potential-findings' ? 'potential' : 'info', 'serious')}
                    >
                        <div className="label"><span className="indicators"></span> Serious</div>
                        <div className="value">{serious}</div>
                    </div>
                    <div 
                        className={`medium ${medium && "cursor-pointer"}`}
                        onClick={() => medium && handleFindingsRedirection(type === 'confirmed-findings' ? 'confirmed' : type === 'potential-findings' ? 'potential' : 'info', 'medium')}
                    >
                        <div className="label"><span className="indicators"></span> Medium</div>
                        <div className="value">{medium}</div>
                    </div>
                    <div 
                        className={`minimum ${minimum && "cursor-pointer"}`}
                        onClick={() => minimum && handleFindingsRedirection(type === 'confirmed-findings' ? 'confirmed' : type === 'potential-findings' ? 'potential' : 'info', 'minimum')}
                    >
                        <div className="label"><span className="indicators"></span> Minimum</div>
                        <div className="value">{minimum}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpenFindingsOverview;
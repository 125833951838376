import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Text } from 'recharts';
import { getItemType } from '../../utils/constants/severity';
import Typography from '../Typography';
import { colorSelector, dynamicColorsSelector } from '../../utils/constants/asset';

const CustomTooltip = ({ active, payload, label, type, labelClassName }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-graph-tooltip">
        <p className="label font-bold capitalize">{label}</p>
        {payload.map((entry, index) => (
          <p key={index} className={`label ${labelClassName ? labelClassName : "capitalize"}`}>
            {`${entry.name}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const CustomTick = (props) => {
  const { x, y, payload } = props;
  const words = payload.value?.split(' ');
  const maxWordsPerLine = 1;

  const formattedText = [];
  for (let i = 0; i < words.length; i += maxWordsPerLine) {
    formattedText.push(words.slice(i, i + maxWordsPerLine).join(' '));
  }

  return (
    <g transform={`translate(${x},${y})`}>
      {formattedText.map((line, index) => (
        <Text
          key={index}
          x={0}
          y={index * 14}
          textAnchor="middle"
          fontSize={12}
          fill="#666"
          className='capitalize'
        >
          {line}
        </Text>
      ))}
    </g>
  );
};

const StackedBar = (props) => {
  const { type, data = [], colors = {}, labelClassName = null } = props;

  // Flatten the data for Recharts
  const graphData = data.map((item) => {
    const flattened = { name: item.name };
    item.value.forEach((v) => {
      flattened[v.name] = v.value;
    });
    return flattened;
  });

  const keys = Array.from(
    new Set(data.flatMap((item) => item.value.map((v) => v.name)))
  );

  const colorsToUse = keys.map((key, index) => {
    if (colors[key]) {
      return colors[key].color;
    }
    return dynamicColorsSelector[index % dynamicColorsSelector.length];
  });

  return (
    <div className="flex flex-row h-[250px] stacked-bar">
      <div className="graph-container">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={graphData}
            margin={{
              top: 20,
              right: 10,
              left: 10,
              bottom: 36,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              interval={0}
              tick={<CustomTick />}
              height={30}
              tickMargin={10}
            />
            <YAxis style={{ fontSize: "14px", textTransform: 'capitalize' }} />
            <Tooltip cursor={{ fill: 'transparent' }} position={{ x: 320, y: 10 }} content={<CustomTooltip type={type} labelClassName={labelClassName}/>} />
            {keys.map((key, index) => (
              <Bar
                key={key}
                barSize={30}
                dataKey={key}
                stackId="a"
                fill={colorsToUse[index % colorsToUse.length]}
                // radius={[10, 10, 0, 0]}
                name={key}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="label">
        <div className="flex items-center flex-[100%] flex-align-center">
          <div className="flex flex-col h-[50px]">
            {keys.map((key, index) => (
              <div className="flex items-center flex-[100%]" key={index}>
                <div
                  className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5"
                  style={{ background: colorsToUse[index % colorsToUse.length] }}
                ></div>
                <Typography variant="caption" className={`${labelClassName ? labelClassName : "capitalize"}`}>
                  {key}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};




export default StackedBar;
import React from 'react';

const CheckedTick = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#12AFB8"/>
            <g clip-path="url(#clip0_14078_27056)">
                <path d="M12.4982 18.475L9.02318 15L7.83984 16.175L12.4982 20.8333L22.4982 10.8333L21.3232 9.65833L12.4982 18.475Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_14078_27056">
                <rect width="20" height="20" fill="white" transform="translate(5 5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default CheckedTick;

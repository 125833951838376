import React from 'react';
// import PropTypes from 'prop-types';
import AccountDropDown from "./AccountDropDown";

import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from "react-redux";
import { setDrawer } from "../../redux/reducers/common";
import {
    AddCustomer,
    AddFindings,
    AddFindingsToThisAsset,
    AddUsers,
    InviteCustomer,
    MyTeam,
    SeeScope, SlackButton,
    StartNewEASM,
    StartNewGenPT,
    StartNewPentest,
    StartNewVA,
    UpdatePentestLandScapeButton
} from "./Menu";
import ExpandedMenu from "./ExpandedMenu";
import styles from './header.module.scss'
import { PermissionProvider } from "../../hoc/withPermissions";
import { SIEMBA_ADMIN, SIEMBA_CUSTOMER, SIEMBA_CUSTOMER_ENGINEER, SIEMBA_TESTER } from "../../utils/roles";
import { useCustomerDetails } from "../../hooks/customer";
import { useImage } from "../../hooks/misc";
import { Divider } from "@mui/material";
import { Link, useLocation, useMatch } from "react-router-dom";
import { useValidateLogin } from "../../hooks/auth";
import { useIsMutating } from "react-query";
import { LOGOUT } from "../../hooks/utils/constants";
import MainLoader from "../Loader/MainLoader";
import { SLACK_CLIENT_ID } from "../../utils/constants";
import Notifications from './Notifications';
import { getMainURL } from './Notifications/notification-helper';
import DropdownButton from '../Button/DropdownButton';
import AssetCriticalityDropDown from '../Inputs/AssetCriticalityDropDown';
import GlobalCompanySelection from '../Inputs/GlobalCompanySelection';

const Header = props => {
    const dispatch = useDispatch()
    const { data } = useValidateLogin()
    const { isMenuExpanded } = useSelector(state => state.common)
    const { data: customer, isFetching } = useCustomerDetails(data?.user?.user_type === 'customer')
    let company_logo_key = data?.user?.user_type === 'customer' ? customer?.company?.logo : '';
    let company_logo_url = useImage(company_logo_key, 'logo')
    const location = useLocation()
    const showMenu = ["/app", '/admin', '/tester'].some(el => location.pathname.includes(el))
    const isLoggingOut = !!useIsMutating(LOGOUT)

    // add findings to this asset
    const testerPath = useMatch("/tester/all_assets/:id/dashboard")
    const adminAssetDashboardPath = useMatch("/admin/all_assets/:id/dashboard")
    const adminPentestDashboardPath = useMatch("/admin/all_pentests/:id/dashboard")
    const siembaGPTAdminPath = useMatch("/admin/genpt")
    const assetLocation = testerPath || adminAssetDashboardPath
    const asset_id = assetLocation?.params?.id
    const mainUrl = getMainURL(data?.user?.role);

    let navHeaders = {
        showUpdatePentestLandScapeButton: true,
        showAddUsers : true,
        showAddCustomer : true,
        showAddFindingsToThisAsset : true,
        showAddFindings : true,
        showMyTeam : true,
        showSlackButton : true,
        showInviteCustomer : true,
        showStartNewPentest : true,
        showNotifications : true,
        showAccountDropDown : true,
        showGlobalCompany: true
    }

    if (useMatch("admin/all_pentests/:pentestId/update" || siembaGPTAdminPath)){
        for (const key in navHeaders) {
            navHeaders[key] = false
        } 

        navHeaders.showAccountDropDown = true
    }

    return (
        <>
            {isLoggingOut && <MainLoader />}
            <div className="clear-both h-[50px] sm:h-[65px] hlg:h-[53px]"></div>
            <header className={"fixed w-full top-0  z-10"}>
                <nav className={`flex items-center ${adminAssetDashboardPath || adminPentestDashboardPath ? "gap-3" : "gap-[26px]"}`}>
                    <div className={"menu"} style={{cursor: "pointer", marginLeft: "10px"}}>
                        <PermissionProvider>
                            {
                                showMenu &&
                                <MenuIcon onClick={() => dispatch(setDrawer(true))} />
                            }
                        </PermissionProvider>
                    </div>
                    <div className={"logo flex-grow"}>
                        <Link to={mainUrl}>
                            <img className="logo-img" src="/images/logo.png" alt="logo" />
                        </Link>
                        {
                            company_logo_url &&
                            <>
                                <Divider orientation={"vertical"} className={"inline !mr-3.5"} />
                                <img src={company_logo_url} alt="" />
                            </>
                        }
                    </div>
                    {/* <PermissionProvider roles={[SIEMBA_CUSTOMER, SIEMBA_ADMIN]}>
                        <div className={"hidden md:block"}>
                            {navHeaders.showUpdatePentestLandScapeButton && 
                            <UpdatePentestLandScapeButton />
                            }
                        </div>
                    </PermissionProvider> */}
                    <PermissionProvider roles={[SIEMBA_ADMIN]}>
                        <div className={"hidden md:block"}>
                            {navHeaders.showAddUsers && 
                            <AddUsers />
                            }
                        </div>
                    </PermissionProvider>
                    {/* <PermissionProvider roles={[SIEMBA_ADMIN]}>
                        <div className={"hidden md:block"}>
                            {navHeaders.showAddCustomer && 
                            <AddCustomer />
                            }
                        </div>
                    </PermissionProvider> */}
                    <PermissionProvider roles={[SIEMBA_TESTER, SIEMBA_ADMIN]}>
                        {
                            asset_id &&  
                            <div className={"hidden md:block"}>
                                {navHeaders.showAddFindingsToThisAsset &&
                                <AddFindingsToThisAsset />
                                }
                            </div>
                        }
                    </PermissionProvider>
                    <PermissionProvider roles={[SIEMBA_TESTER, SIEMBA_ADMIN]}>
                        <div className={"hidden md:block"}>
                            {navHeaders.showGlobalCompany && 
                              <GlobalCompanySelection />
                            }
                        </div>
                    </PermissionProvider>
                    <PermissionProvider roles={[SIEMBA_ADMIN, SIEMBA_TESTER]}>
                        <div className={"hidden md:block"}>
                            {navHeaders.showAddFindings && 
                            <DropdownButton title='ADD' color="!bg-secondary-700" showAddIcon={true} components={[
                                <AddFindings key="findings"/>,
                                data?.user?.role == SIEMBA_ADMIN ? <AddCustomer key="customers"/> : null
                              ]}/>
                            
                            }
                        </div>
                    </PermissionProvider>
                    <PermissionProvider roles={[SIEMBA_ADMIN]}>
                        <div className={"hidden md:block"}>
                            {navHeaders.showMyTeam && 
                            <MyTeam />
                            }
                        </div>
                    </PermissionProvider>
                    {/*<PermissionProvider roles={[SIEMBA_CUSTOMER, SIEMBA_CUSTOMER_ENGINEER]}>*/}
                    {/*    <div className={"hidden md:block"}>*/}
                    {/*        <SeeScope/>*/}
                    {/*    </div>*/}
                    {/*</PermissionProvider>*/}

                    {
                        (SLACK_CLIENT_ID && !isFetching && !customer?.slackConnectionId) &&
                        <PermissionProvider roles={[SIEMBA_CUSTOMER]}>
                            <div className={"hidden md:block"}>
                              {navHeaders.showSlackButton && 
                                <SlackButton />
                              }
                            </div>
                        </PermissionProvider>
                    }
                    <PermissionProvider roles={[SIEMBA_CUSTOMER]}>
                        <div className={"hidden md:block"}>
                            {navHeaders.showInviteCustomer && 
                            <InviteCustomer />
                            }
                        </div>
                    </PermissionProvider>
                    <PermissionProvider roles={[SIEMBA_CUSTOMER, SIEMBA_ADMIN]}>
                        <div className={"hidden md:block"}>
                            {navHeaders.showStartNewPentest && 
                            <DropdownButton components={[
                                <StartNewEASM key="easm" />,
                                <StartNewVA key="va" />,
                                <StartNewGenPT key="genpt" from="header" />,
                                <StartNewPentest key="pentest" />                 
                              ]}/>
                            }
                        </div>
                    </PermissionProvider>
                    <PermissionProvider roles={[SIEMBA_CUSTOMER_ENGINEER]}>
                        <div className={"hidden md:block"}>
                            {navHeaders.showStartNewPentest && 
                            <DropdownButton components={[
                                <StartNewEASM key="easm" />,
                                <StartNewGenPT key="genpt" />
                              ]}/>
                            }
                        </div>
                    </PermissionProvider>
                    <div className={"my-auto"}>
                        {navHeaders.showNotifications && 
                        <PermissionProvider>
                            <Notifications />
                        </PermissionProvider>
                        }
                    </div>
                    <div className={"my-auto"}>
                        {navHeaders.showAccountDropDown &&
                        <PermissionProvider>
                            <AccountDropDown />
                        </PermissionProvider>
                        }
                    </div>
                </nav>
                <div className={`${styles.expandedMenu} ${isMenuExpanded && styles.active} md:hidden`}>
                    <ExpandedMenu />
                </div>
            </header>
        </>
    );
};

export default Header;
import React, { useState, useRef, useEffect } from 'react';
import styles from './buttons.module.scss'; // Ensure you have this CSS module with the fade effect
import { StartNewEASM, StartNewPentest } from '../Header/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from "@mui/icons-material/Add";
import Button from '.';

const DropdownButton = ({ components, title = 'START', color= 'bg-primary-500', showAddIcon = false }) => {
  const [currentComponent, setCurrentComponent] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside of the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {

    if(components){
      components.map((ele, index) => {
        if(ele == null)
          components.splice(1, index)
      })
    }

  }, [components])

  return (
    <div className="relative inline-flex items-center" ref={dropdownRef}>
      <Button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className={`w-32 px-4 py-2 ${color} text-white rounded-md flex items-center justify-center`}
      >
       { showAddIcon && <AddIcon />}
        <span className="ml-2">{title}</span>
        <span className="ml-2">
          {
            isDropdownOpen ?
              <KeyboardArrowUpIcon />
            :
              <KeyboardArrowDownIcon />
          }
        </span>
      </Button>
      {isDropdownOpen && (
        <ul className="absolute top-full left-0 mt-2 bg-white rounded-md shadow-lg w-56 px-3">
          {components.map((component, index) => (
            <li
              key={index}
              onClick={() => {
                setCurrentComponent(index);
                setIsDropdownOpen(false);
              }}
              className={`py-3 text-primary-500 font-semibold text-base hover:bg-gray-200 cursor-pointer ${index < components.length - 1 && 'menu-border'}`}
            >
              {component}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownButton;

import {useMutation, useQuery} from "react-query";
import {LOGOUT, VALIDATE_LOGIN, VALIDATE_TOKEN} from "./utils/constants";
import {validateLogin} from "../api/queries/auth";
import Storage from "../utils/Storage";
import axios from "axios";
import {logout} from "../actions/authActions";
import { userTypes } from '../utils/constants';
import { setIntegrationsEnabled } from "../redux/reducers/auth";
import { useDispatch } from "react-redux";

export const useValidateLogin = () => {
    const dispatch = useDispatch()

    return useQuery(VALIDATE_LOGIN, validateLogin, {
        retry: false, staleTime: Infinity,
        enabled: Boolean(Storage.token),
        onSuccess: (res) => {
            let companyId = res?.user?.currentCompanyId || null
            if (res.user.user_type !== userTypes.PROVIDER) {
              // Storage.setData('company_id', companyId);
              Storage.encryptStorageData('company_id', JSON.stringify({id: companyId}));
              Storage.encryptStorageData('easm_enabled', JSON.stringify(res?.user?.genpt_enabled || false));
              Storage.encryptStorageData('genpt_enabled', JSON.stringify(res?.user?.easm_enabled || false));
              // axios.defaults.headers.common.company_id = companyId;
            }
            // Storage.setGenpt(res.user.genpt_enabled || false);
            // Storage.setEasm(res.user.easm_enabled || false);
            dispatch(setIntegrationsEnabled({
                is_genpt_enabled: res?.user?.is_genpt_enabled || false,
              }))
        },
        onError: (e) => {
            if (e?.response?.status === 401) {
                logout()
            }
        }
    })
}

export const useFetchUserDataFromToken = (options = {}) => {
    const {
      token = '',
      ...queryOptions
  } = options
    return useQuery(VALIDATE_TOKEN, async () => {
      let res = await axios.get(`/auth/validate_token`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
      return res.data
    }, {
      enabled: !!token,
      ...queryOptions
    })
  };

export const useLogout = () => {
    return useMutation(async () => {
        let res = await axios.post('/auth/logout')
        return res.data
    }, {
        mutationKey: LOGOUT,
        onSuccess: (res) => {
            if (res?.success)
                logout()
        }
    })
}

export const useUserRole = () => {
    const {data} = useValidateLogin()
    return data?.user?.role
}

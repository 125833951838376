import React from "react";
import styles from "../../../../Admin/Dashboard/dashboard.module.scss";
import Typography from "../../../../../../components/Typography";
import StatusUpdateIcon from "../../../../../../components/SvgIcons/StatusUpdateIcon";
import { setIPCountModal, setQualysReportModal } from "../../../../../../redux/reducers/dashboard";
import { useDispatch } from "react-redux";
import Info from "../../../../../../components/Info";
import GenVAIcon from "../../../../../../components/SvgIcons/GenVAIcon";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import moment from "moment";
import { Link } from "react-router-dom";

const OverallVAOverview = (props) => {
  const genVAOverallData = props.genVAOverallData;
  const genVAOverallInsightData = props.genVAOverallInsightData;
  const mainUrl = props.mainUrl;

  return (
    <div className={`${styles.overview} h-full padding-0`}>
        <div className="grid grid-cols-12 gap-x-3">
          <div className={`xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 overview-content`}>
            <div className={styles.background}></div>
            <div className="grid grid-cols-12">
                <div className="xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                    <div className="grid grid-cols-12 gap-x-3 mb-4">
                        <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6 left-section">
                            <GenVAIcon />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-6 right-section text-right">
                            Dashboard last updated on { moment(genVAOverallData?.data?.last_scan_date).format('MM/DD/YYYY')}
                        </div>
                    </div>
                </div>  
                <div className='xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12'>
                    <div className="grid grid-cols-12 gap-x-3">
                        <div className='xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12'>
                        <Typography variant={"h5"} className={`font-bold leading-[140%]`}>
                            {genVAOverallInsightData?.bannerTitle}
                        </Typography>
                        </div>
                    </div>
                </div>

            </div>
            <div className='xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 mt-4'>
                <div className="grid grid-cols-12 assets-details">
                    <div className='xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-3 all-assets-count'>
                        <span className="label">
                            <span className="label-name font-semibold">Possible Exploits</span>
                        </span>
                        <span className="value">
                            <div 
                                className={`font-semibold ${Number(genVAOverallData?.data?.findings_with_possible_exploits) && "cursor-pointer text-underline"}`} 
                                onClick={() => Number(genVAOverallData?.data?.findings_with_possible_exploits) && props.handleFindingsRedirection('RTI-Exploitability')}
                            >
                                {Number(genVAOverallData?.data?.findings_with_possible_exploits || 0)}
                            </div>
                        </span>
                    </div>
                    <div className='xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-3 va-banner-tile total-findings'>
                        <span className="label">
                            <span className="label-name font-semibold">Possible Malwares</span>
                        </span>
                        <span className={`value`}>
                            <div onClick={() => Number(genVAOverallData?.data?.findings_with_possible_malware) && props.handleFindingsRedirection('RTI-Malware')}>
                                <p className={`font-semibold ${Number(genVAOverallData?.data?.findings_with_possible_malware) && 'cursor-pointer text-underline'}`}>{Number(genVAOverallData?.data?.findings_with_possible_malware) || 0}</p>
                            </div>
                        </span>
                    </div>
                    <div className='xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-3 va-banner-tile total-findings'>
                        <span className="label">
                            <span className="label-name font-semibold">All Scans</span>
                        </span>
                        <span className={`value`}>
                            <div>
                                <p className={`font-semibold ${Number(genVAOverallData?.data?.banner?.all_scans) && "cursor-pointer text-underline"}`}>
                                    <Link className={"link"} target={"_blank"} to={`${mainUrl}/all_va`}>
                                        {Number(genVAOverallData?.data?.banner?.all_scans) || 0}
                                    </Link>
                                </p>
                            </div>
                        </span>
                    </div>
                    <div className='xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-3 va-banner-tile total-findings active-inactive-ratio'>
                        <span className="label">
                            <span className="label-name font-semibold">Active v/s Inactive Hosts</span>
                        </span>
                        <span className={`value`}>
                            <div>
                                <p className="font-semibold">{genVAOverallData?.data?.banner?.active_inactive_hosts}</p>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default OverallVAOverview;

import React, { useContext } from 'react';
import ReactTable from '../Table';
import { Link } from 'react-router-dom';
import { Parser } from 'html-to-react';
import {
  colorSelector,
  CTEMInsight1001Colors,
  CTEMInsight1002Colors,
  CTEMInsight1003Colors,
  EASMInsight1Colors,
  latestScanBreakdownColors,
  pentestFindingSeverityColors,
  SSLTLSColors,
  tlsColors,
  vaSeverityColors,
  assetCriticalityColors,
  rtiColors,
  statusBreakdownColors
} from '../../utils/constants/asset';
import { LayoutContext } from '../../Providers/LayoutProvider';
import Typography from '../Typography';
import StackedBar from '../Widgets/StackedBarChart';
import BarChartComponent from '../Widgets/barChart';
import CounterChartWidget from '../Widgets/CounterChartWidget';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import moment from 'moment';
import NoData from '../NoData';

const KeyInsightDetail = ({ description, data, insightId, count }) => {
  const htmlParser = new Parser();

  const { mainUrl } = useContext(LayoutContext);

  const processColumns = (metaData) => {
    return metaData.columns.map((col) => (
      {
      Header: col.title,
      accessor: col.accessor,
      className: col.className || '',
      disableSortBy: col.sort ?? false,
      Cell: ({ value, row }) => {
        if (col.cellType === 'checkbox') {
          return value ? (
            <CheckBoxIcon style={{ color: '#3ADA7A' }} />
          ) : (
            <DisabledByDefaultIcon style={{ color: '#CC394F' }} />
          );
        }
        if (col.cellType === 'boolean') {
          return value == true ? <div>Yes</div> : <div>No</div>;
        }
        if (col.cellType === 'comma') {
          return value.join(', ');
        }
        // If a direct link is provided, use it
        if (col.link) {
          const linkValue = row.original[col.linkValue];
          const dynamicLink = col.link.replace('${' + col.linkValue + '}', linkValue);
          return (
            <Link className="link" target="_blank" to={`${mainUrl}${dynamicLink}`}>
              {value}
            </Link>
          );
        }
        if (col.cellType === 'date') {
          if (!value) return '-';
          return moment(value).format('DD/MM/YYYY');
        }

        // Handle conditional links dynamically
        if (col.conditions) {
          const rowData = row.original;
          const matchedCondition = col.conditions.find(({ condition }) => {
            try {
              // Use Function constructor to evaluate conditions securely
              return new Function('row', `return ${condition};`)(rowData);
            } catch (error) {
              console.error('Error evaluating condition:', error);
              return false;
            }
          });

          if (matchedCondition) {
            let dynamicLink = matchedCondition.link;
            // Replace placeholders
            dynamicLink = dynamicLink.replace(/\$\{row\.(\w+)\}/g, (_, key) => rowData[key] || '');

            return (
              <Link className="link" target="_blank" to={`${mainUrl}${dynamicLink}`}>
                {value}
              </Link>
            );
          }
        }

        // Default case
        return <span className={col.className || ''}>{value}</span>;
      },
    }));
  };

  const graphData = data?.graphData?.data || [];
  const graphType = data?.graphData?.type;
  const labelClassName = data?.graphData?.labelClassName;

  const ColorEnums = {
    tlsColors,
    SSLTLSColors,
    CTEMInsight1001Colors,
    CTEMInsight1002Colors,
    CTEMInsight1003Colors,
    pentestFindingSeverityColors,
    EASMInsight1Colors,
    vaSeverityColors,
    statusBreakdownColors,
    latestScanBreakdownColors,
    rtiColors,
    assetCriticalityColors,
  };

  // console.log("table.data", data?.table.data);

  const Insight2Color = {};
  const Insight7Color = {};

  if(Array.isArray(graphData)){
    data?.graphData?.data?.forEach((graph, index) => {
      Insight2Color[graph?.name?.toUpperCase()] = {
        name: graph?.name?.toUpperCase(),
        color: colorSelector[index] || '#000000',
      };
  
      Insight7Color[graph?.name?.toUpperCase()] = {
        name: graph?.name?.toUpperCase(),
        color: colorSelector[index] || '#000000',
      };
    });
  }

  console.log('-==-- data?.graphData?.colorSet', data?.graphData?.colorSet);
  const selectedEnumKey = data?.graphData?.colorSet || 'tlsColors';
  console.log('-==-- selectedEnumKey', selectedEnumKey);
  const colors =
    selectedEnumKey === 'Insight2Color'
      ? Insight2Color
      : selectedEnumKey === 'Insight7Color'
      ? Insight7Color
      : ColorEnums[selectedEnumKey] || tlsColors;

  console.log('-=- selected colors', colors);
  let graphComponent = null;

  if (graphType && graphData) {
    switch (graphType) {
      case 'bar':
        graphComponent = (
          <BarChartComponent
            className={data?.graphData?.className}
            graphData={graphData}
            colors={colors}
          />
        );
        break;
      case 'stack':
        graphComponent = 
          <div className={data?.graphData?.className}>
            <StackedBar data={graphData} colors={colors} labelClassName={labelClassName} />
          </div>
        break;
      case 'donut':
        graphComponent = (
          <div className={data?.graphData?.className}>
            <CounterChartWidget data={graphData || []} title={''} colors={colors} />
          </div>
        );
        break;
      case 'card':
        graphComponent = (
          <div className="flex flex-col h-full justify-center align-center">
            <div
              style={{
                width: '250px',
                height: '100px',
                borderRadius: 10,
                background:
                  'linear-gradient(180deg, rgba(0, 147, 164, 0.59) 9.83%, var(--secondary-400) 66.81%)',
                textAlign: 'center',
              }}
            >
              <div>
                <Typography variant="h7" className="p-2" style={{ color: 'white' }}>
                  {data?.graphData?.graphHeader}
                </Typography>
              </div>
              <div
                className="w-full h-full align-center"
                style={{
                  backgroundColor: 'white',
                  textAlign: 'center',
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                  border: '2px solid var(--primary-100)',
                }}
              >
                <div className="flex flex-wrap max-w-[250px] max-h-[80px] overflow-y-auto p-2">
                  {Array.isArray(graphData) ? (
                    graphData.length > 0 ? (
                      graphData.map((item, index) => (
                        <div key={index} className="flex items-center">
                          <Typography variant="h6" className="whitespace-nowrap">
                            {item}
                          </Typography>
                          {index < graphData.length - 1 && (
                            <Typography variant="h6" className="whitespace-nowrap mr-1">
                              ,
                            </Typography>
                          )}
                        </div>
                      ))
                    ) : (
                      <NoData message="No data available" />
                    )
                  ) : graphData ? (
                    <Typography variant="h6" className="whitespace-nowrap">
                      {graphData}
                    </Typography>
                  ) : (
                    <NoData message="No data available" />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
        break;
      default:
        graphComponent = <div>Unsupported graph type</div>;
        break;
    }
  }

  const hasGraph = graphData.length > 0;
  const hasTable = data?.listing?.length > 0;

  return (
    <div className="insight-container flex flex-wrap gap-5">
      <div
        className={`flex gap-3 flex-wrap w-full ${hasGraph ? 'lg:flex-nowrap' : 'lg:flex-wrap'}`}
      >
        <div className="description flex-1 p-2 bg-white shadow rounded-lg">
          <Typography variant="h7" className="text-headings">
            Observation
          </Typography>
          <div>{htmlParser.parse(data?.observation.replace('{{COUNT}}', count.toString()))}</div>
          <Typography variant="h7" className="text-headings mt-5">
            Recommended Action
          </Typography>
          <div>{htmlParser.parse(data?.recommended_action)}</div>
        </div>
{hasGraph && (
    <div
      className={`graph flex-1 p-5 bg-white shadow rounded-lg h-[320px] ${data?.graphData?.className}`}
    >
      {graphComponent}
    </div>
  )}

        {!hasGraph && hasTable && (
          <div className="table-container flex-1 p-5 bg-white shadow rounded-lg">
            {data.listing.map((table, index) => (
              <div key={index} className="mb-5">
                <Typography variant="h7" className="text-headings capitalize mb-5">
                  {table.tableName}
                </Typography>
                <ReactTable
                  columns={processColumns(table.metaData)}
                  data={table.data || []}
                  className="table w-full"
                  headerClassName="background rounded sticky z-[5] top-0"
                  bodyClassName="text-headings"
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {hasGraph && hasTable && (
        <div className="table-container w-full grid gap-5 grid-cols-1">
          {data.listing.map((table, index) => (
            <div key={index} className="p-5 bg-white shadow rounded-lg">
              <Typography variant="h7" className="text-headings capitalize mb-5">
                {table.tableName}
              </Typography>
              <ReactTable
                columns={processColumns(table.metaData)}
                data={table.data || []}
                className="table w-full"
                headerClassName="background rounded sticky z-[5] top-0"
                bodyClassName="text-headings"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default KeyInsightDetail;

import MonitorIcon from "@mui/icons-material/Monitor";
import React from "react";
import ApiIcon from "../../components/SvgIcons/ApiIcon";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import FlightIcon from "../../components/SvgIcons/FlightIcon";
import ExternalIcon from "../../components/SvgIcons/ExternalIcon";
import InternalIcon from "../../components/SvgIcons/InternalIcon";
import CloudIcon from "../../components/SvgIcons/CloudIcon";
import WordpressIcon from "../../components/SvgIcons/WordpressIcon"
import DraftIcon from "../../components/SvgIcons/DraftIcon";
import CompletedIcon from "../../components/SvgIcons/CompletedIcon";
import RemediatingIcon from "../../components/SvgIcons/RemediatingIcon";
import RetestReadyIcon from "../../components/SvgIcons/RetestReadyIcon";
import RetestFailedIcon from "../../components/SvgIcons/RetestFailedIcon";
import ReportReadyIcon from "../../components/SvgIcons/ReportReadyIcon";
import ReportDeliveredIcon from "../../components/SvgIcons/ReportDeliveredIcon";
import { API, MOBILE_APP, WEB_APP } from "../../containers/pages/PentestScope/AddNewAssets/utils/asset_constants";

export const platforms = {
    web_app: {
        title: "Web App",
        fullTitle: "Web Application",
        icon: (<MonitorIcon className={"text-primary-500 align-top"} />)
    },
    api: {
        title: "API",
        icon: <ApiIcon />
    },
    mobile_app: {
        title: "Mobile App",
        fullTitle: "Mobile Application",
        icon: <PhoneAndroidIcon className={"text-tertiary-600"} />
    },
    it_infra: {
        title: "IT Infrastructure",
        icon: <DeviceHubIcon className={"text-primary-500"} />
    },
    cloud: {
        title: "Cloud",
        icon: <CloudIcon />
    },
    wordpress: {
        title: "WordPress",
        icon: <WordpressIcon />
    }
}

export const stages = {
    pending: {
        title: "Pre - Requisites",
        icon: <DraftIcon />
    },
    inflight: {
        title: "Inflight",
        icon: <FlightIcon />
    },
    testing_completed: {
        title: "Testing Completed",
        icon: <CompletedIcon />
    },
    remediation: {
        title: "Remediating",
        icon: <RemediatingIcon />
    },
    retest_ready: {
        title: "Retest Ready",
        icon: <RetestReadyIcon />
    },
    retest_failed: {
        title: "Retest Failed",
        icon: <RetestFailedIcon />
    },
    report_ready: {
        title: "Report Ready",
        icon: <ReportReadyIcon />
    },
    report_delivered: {
        title: "Report Delivered",
        icon: <ReportDeliveredIcon />
    }
}

export const testing_types = {
    greybox: {
        title: "Grey Box",
    },
    blackbox: {
        title: "Black Box"
    },
    whitebox: {
        title: "White Box"
    }
}
export const modes = {
    internal: {
        title: "Internal",
        icon: <InternalIcon />
    },
    external: {
        title: "External",
        icon: <ExternalIcon />
    },
}

export const os_type = {
    android: {
        title: "Android"
    },
    ios: {
        title: "iOS"
    },
    windows: {
        title: "Windows"
    },
}

export const pentestStatusColors = {
    inflight: {
        name: 'Inflight',
        color: "var(--primary-500)"
    },
    draft: {
        name: 'Draft',
        color: "var(--tertiary-600)"
    },
    retest: {
        name: 'Retest',
        color: "var(--functional-purple)"
    },
    upcoming: {
        name: 'Upcoming',
        color: "var(--secondary-700)"
    },
    closed: {
        name: 'Closed',
        color: "var(--grey2)"
    },
}

export const latestScanBreakdownColors = {
    new: {
        name: 'New',
        color: "#12AFB8"
    },
    active: {
        name: 'Active',
        color: "#9A2335"
    },
    'retest ready': {
        name: 'Retest Ready',
        color: "#228EBC",
    },
    're-opened': {
        name: 'Re-Opened',
        color: "#FE7754"
    },
    false_positive: {
        name: 'False Positive',
        color: "#C745F4"
    },
}

export const statusBreakdownColors = {
    new: {
        name: 'New',
        color: "#12AFB8"
    },
    active: {
        name: 'Active',
        color: "#9A2335"
    },
    retest_ready: {
        name: 'Retest Ready',
        color: "#228EBC",
    },
    're-opened': {
        name: 'Re-Opened',
        color: "#FE7754"
    },
    false_positive: {
        name: 'False Positive',
        color: "#C745F4"
    },
}

export const vulAssessmentFindingSeverityColors = {
    urgent: {
        name: 'Urgent',
        color: "#9A2335"
    },
    critical: {
        name: 'Critical',
        color: "#CC394F"
    },
    serious: {
        name: 'Serious',
        color: "#FE7754"
    },
    medium: {
        name: 'Medium',
        color: "#FFE765"
    },
    minimum: {
        name: 'Minimum',
        color: "#CFE2F3"
    },
}

export const pentestFindingSeverityColors = {
    critical: {
        name: 'Critical',
        color: "#9A2335"
    },
    high: {
        name: 'High',
        color: "#CC394F"
    },
    medium: {
        name: 'Medium',
        color: "#FE7754"
    },
    low: {
        name: 'Low',
        color: "#FFE765"
    },
    info: {
        name: 'Info',
        color: "#CFE2F3"
    },
}

export const exploitabilityBreakdownColors = {
    yes: {
        name: 'Yes',
        color: "#9A2335"
    },
    no: {
        name: 'No',
        color: "#228EBC"
    },
}

export const associatedMalwareColors = {
    yes: {
        name: 'Yes',
        color: "#CC394F"
    },
    no: {
        name: 'No',
        color: "#C745F4"
    },
}


export const assetStages = {
    prerequisites: {
        key: "prerequisites",
        title: "Pre - Requisites",
        icon: <DraftIcon />
    },
    testing: {
        key: "testing",
        title: "Testing",
        icon: <FlightIcon />
    },
    remediation: {
        key: "remediation",
        title: "Remediation",
        icon: <RemediatingIcon />
    },
    final_report: {
        key: "final_report",
        title: "Final Report",
        icon: <ReportReadyIcon />
    }
}

export const assetCriticalityMapping = {
    "P1": 'Mission Critical',
    "P2": 'Business Critical',
    "P3": 'Significant Impact',
    "P4": 'Limited Impact',
    "P5": 'Negligible Impact',
};

export const reverseAssetCriticalityMapping = {
    'Mission Critical': 'P1',
    'Business Critical': 'P2',
    'Significant Impact': 'P3',
    'Limited Impact': 'P4',
    'Negligible Impact': 'P5',
};

export const scopedInventoryColors = {
    easm_scoped_assets: {
        name: 'Scoped via EASM',
        color: "#C745F4"
    },
    manually_scoped_assets: {
        name: 'Manually Scoped',
        color: "#12AFB8"
    },
    web_app: {
        name: 'Web App',
        color: "#12AFB8"
    },
    mobile_app: {
        name: 'Mobile App',
        color: "#FE7754"
    },
    api: {
        name: 'APIs',
        color: "#228EBC",
    },
    it_infra: {
        name: 'Infra',
        color: "#C745F4"
    },
}

export const discoveryColors = {
    critical: {
        name: 'Critical',
        color: "#9A2335"
    },
    high: {
        name: 'High',
        color: "#CC394F"
    },
    medium: {
        name: 'Medium',
        color: "#FE7754",
    },
    low: {
        name: 'Low',
        color: "#FFE765"
    },
    info: {
        name: 'Informational',
        color: "#CFE2F3"
    },
}

export const subdomainGrade = {
    "a+": {
        name: 'A+',
        color: '#339933'
    },
    a: {
        name: 'A',
        color: "#3ADA7A"
    },
    "a-": {
        name: "A-",
        color: "#66CC66"
    },
    b: {
        name: 'B',
        color: "#F6C000"
    },
    c: {
        name: 'C',
        color: "#FE7754",
    },
    d: {
        name: 'D',
        color: "#F65C5C"
    },
    e: {
        name: 'E',
        color: "#CC394F"
    },
    f: {
        name: 'F',
        color: "#9A2335"
    },
    t: {
        name: 'T',
        color: "#CC0000"
    },
    m: {
        name: 'M',
        color: "#990000"
    },
    "no_data": {
        name: 'No Data',
        color:'#A1A5B7'
    }
}

export const cipherSuites = {
    "1.0": {
        name: 'TLS 1.0',
        color: "#228EBC"
    },
    "1.1": {
        name: 'TLS 1.1',
        color: "#6247AA"
    },
    "1.2": {
        name: 'TLS 1.2',
        color: "#FE7754",
    },
    "1.3": {
        name: 'TLS 1.3',
        color: "#C745F4"
    }
}

export const techStack = {
    "php": {
        name: 'PHP',
        color: "#12AFB8"
    },
    ".net": {
        name: '.NET',
        color: "#6247AA"
    },
    "java": {
        name: 'Java',
        color: "#FE7754",
    },
    "node.js": {
        name: 'Node.js',
        color: "#3ADA7A"
    },
    "others": {
        name: 'Others',
        color: "#CFE2F3"
    },
}

export const prioritizationColors = {
    critical: {
        name: 'Critical',
        color: "#9A2335",
    },
    high: {
        name: 'High',
        color: "#CC394F"
    },
    moderate: {
        name: 'Moderate',
        color: "#FE7754",
    },
    low: {
        name: 'Low',
        color: "#FFE765"
    },
    minimal: {
        name: 'Minimal',
        color: "#CFE2F3"
    },
}

export const confirmedRTIColors = {
    "high_data_loss": {
        name: "High Data Loss",
        color: '#CC394F',
    },
    "high_lateral_movement": {
        name: "High Lateral Movement",
        color: '#CC394F',
    },
    "denial_of_service": {
        name: "Denial of Service",
        color: '#CC394F',
    },
    "patch_not_available": {
        name: "Patch Not Available",
        color: '#CC394F',
    },
    "privilege_escalation": {
        name: "Privilege Escalation",
        color: '#CC394F',
    },
    "unauthenticated_exploitation": {
        name: "Unauthenticated Exploitation",
        color: '#CC394F',
    },
    "remote_code_execution": {
        name: "Remote Code Execution",
        color: '#CC394F',
    },
    "actively_attacks": {
        name: "Actively Attacks",
        color: '#CC394F',
    },
    "malware": {
        name: "Malware",
        color: '#CC394F',
    },
    "zero-day": {
        name: "Zero-Day",
        color: '#CC394F',
    },
    "public_exploit": {
        name: "Public Exploit",
        color: '#CC394F',
    },
    "predicted_high_risk": {
        name: "Predicted High Risk",
        color: '#CC394F',
    },
    "easy_exploit": {
        name: "Easy Exploit",
        color: '#CC394F',
    },
    "exploit_kit": {
        name: "Exploit Kit",
        color: '#CC394F',
    },
    "wormable": {
        name: "Wormable",
        color: '#CC394F',
    },
    "solorigate_sunburst": {
        name: "Solorigate Sunburst",
        color: '#CC394F',
    },
    "ransomware": {
        name: "Ransomware",
        color: '#CC394F',
    },
    "cisa_known_exploited_vulnerabilities": {
        name: "CISA Known Exploited Vulnerabilities",
        color: '#CC394F',
    }
}

export const potentialRTIColors = {
    "high_data_loss": {
        name: "High Data Loss",
        color: '#228EBC',
    },
    "high_lateral_movement": {
        name: "High Lateral Movement",
        color: '#228EBC',
    },
    "denial_of_service": {
        name: "Denial of Service",
        color: '#228EBC',
    },
    "patch_not_available": {
        name: "Patch Not Available",
        color: '#228EBC',
    },
    "privilege_escalation": {
        name: "Privilege Escalation",
        color: '#228EBC',
    },
    "unauthenticated_exploitation": {
        name: "Unauthenticated Exploitation",
        color: '#228EBC',
    },
    "remote_code_execution": {
        name: "Remote Code Execution",
        color: '#228EBC',
    },
    "actively_attacks": {
        name: "Actively Attacks",
        color: '#228EBC',
    },
    "malware": {
        name: "Malware",
        color: '#228EBC',
    },
    "zero-day": {
        name: "Zero-Day",
        color: '#228EBC',
    },
    "public_exploit": {
        name: "Public Exploit",
        color: '#228EBC',
    },
    "predicted_high_risk": {
        name: "Predicted High Risk",
        color: '#228EBC',
    },
    "easy_exploit": {
        name: "Easy Exploit",
        color: '#228EBC',
    },
    "exploit_kit": {
        name: "Exploit Kit",
        color: '#228EBC',
    },
    "wormable": {
        name: "Wormable",
        color: '#228EBC',
    },
    "solorigate_sunburst": {
        name: "Solorigate Sunburst",
        color: '#228EBC',
    },
    "ransomware": {
        name: "Ransomware",
        color: '#228EBC',
    },
    "cisa_known_exploited_vulnerabilities": {
        name: "CISA Known Exploited Vulnerabilities",
        color: '#228EBC',
    }
}

export const rtiColors = {
    "high_data_loss": {
        name: "High Data Loss",
        color: '#C30224',
    },
    "high_lateral_movement": {
        name: "High Lateral Movement",
        color: '#D1105A',
    },
    "denial_of_service": {
        name: "Denial of Service",
        color: '#CD5521',
    },
    "patch_not_available": {
        name: "Patch Not Available",
        color: '#342067',
    },
    "privilege_escalation": {
        name: "Privilege Escalation",
        color: '#023E8A',
    },
    "unauthenticated_exploitation": {
        name: "Unauthenticated Exploitation",
        color: '#127391',
    },
    "remote_code_execution": {
        name: "Remote Code Execution",
        color: '#E4AB37',
    },
    "actively_attacks": {
        name: "Actively Attacks",
        color: '#800020',
    },
    "malware": {
        name: "Malware",
        color: '#4DA2E5',
    },
    "zero-day": {
        name: "Zero-Day",
        color: '#6247AA',
    },
    "public_exploit": {
        name: "Public Exploit",
        color: '#475E6C',
    },
    "predicted_high_risk": {
        name: "Predicted High Risk",
        color: '#10635F',
    },
    "easy_exploit": {
        name: "Easy Exploit",
        color: '#013654',
    },
    "exploit_kit": {
        name: "Exploit Kit",
        color: '#6D4725F5',
    },
    "wormable": {
        name: "Wormable",
        color: '#D117B8',
    },
    "solorigate_sunburst": {
        name: "Solorigate Sunburst",
        color: '#34CCD4',
    },
    "ransomware": {
        name: "Ransomware",
        color: '#355A00',
    },
    "cisa_known_exploited_vulnerabilities": {
        name: "CISA Known Exploited Vulnerabilities",
        color: '#3ADA7A',
    }
}

export const vulnerabilityByAgeColors = {
    "0-7_days": {
        name: "0-7 days",
        color: '#9A2335',
    },
    "8-30_days": {
        name: "8-30 days",
        color: '#CC394F',
    },
    "31-60_days": {
        name: "31-60 days",
        color: '#FE7754',
    },
    "61-90_days": {
        name: "61-90 days",
        color: '#FFE765',
    },
    "90+_days":{
        name: "90+ days",
        color: '#CEDCE9',
    }
}

export const vaSeverityColors = {
    "urgent": {
        name: "Urgent",
        color: '#9A2335'
    },
    "critical": {
        name: "Critical",
        color: '#CC394F'
    },
    "serious": {
        name: "Serious",
        color: '#FE7754'
    },
    "medium": {
        name: "Medium",
        color: '#FFE765'
    },
    "minimum": {
        name: "Minimum",
        color: '#CEDCE9'
    }
}

export const validationColors = {
    '9.0 - 10': {
        name: '9.0 - 10',
        color: "#9A2335",
    },
    '7.0 - 8.9': {
        name: '7.0 - 8.9',
        color: "#CC394F"
    },
}

export const tlsColors = {
    'SSL': {
        name: 'SSL',
        color: "#FF0000",
    },
    'TLS 1.0': {
        name: 'TLS 1.0',
        color: "#FF3300"
    },
    'TLS 1.1': {
        name: 'TLS 1.1',
        color: "#FF6600"
    },
    'TLS 1.2': {
        name: 'TLS 1.2',
        color: "#FFCC00"
    },
    'TLS 1.3': {
        name: 'TLS 1.3',
        color: "#339933"
    },
    "No Data": {
        name: 'No Data',
        color:'#A1A5B7'
    }
}

export const assignedStatusColors = {
    'assigned': {
        name: 'Assigned',
        color: "#12AFB8",
    },
    'unassigned': {
        name: 'Unassigned',
        color: "#7E8299"
    },
}

export const CTEMInsight1001Colors = {
    'mission_critical': {
        name: 'Pentest',
        color: "#228EBC",
    },
    'business_critical': {
        name: 'Vuln. Assessment',
        color: "#C745F4"
    },
}

export const CTEMInsight1002Colors = {
    'mission_critical': {
        name: 'Mission Critical',
        color: "#9A2335",
    },
    'business_critical': {
        name: 'Business Critical',
        color: "#CC394F"
    },
}

export const CTEMInsight1003Colors = {
    'critical': {
        name: 'Pentest',
        color: "#228EBC",
    },
    'high': {
        name: 'Vuln. Assessment',
        color: "#C745F4"
    },
}

export const DomainGradeColors = {
    'a': {
        name: 'A',
        color: '#3ADA7A'
    },
    'b': {
        name: 'B',
        color: '#F6C000'
    },
    'c': {
        name: 'C',
        color: '#FE7754'
    },
    'd': {
        name: 'D',
        color: '#F65C5C'
    },
    'e': {
        name: 'E',
        color: '#CC394F'
    },
    'f': {
        name: 'F',
        color: '#9A2335'
    },
}

export const SubdomainColors = {
    'active': {
        name: 'Active',
        color: '#228EBC'
    },
    'inactive': {
        name: 'Inactive',
        color: '#B6E7F2'
    }
}

export const MonitoredColors = {
    'monitored': {
        name: 'monitored',
        color: '#12AFB8'
    },
    'unmonitored': {
        name: 'unmonitored',
        color: '#B3ECEC'
    }
}

export const CertificateIssuerColors = {
    'google': {
        name: 'Google',
        color: '#6247AA'
    },
    'amazon': {
        name: 'Amazon',
        color: '#C745F4'
    },
    'godaddy': {
        name: 'Godaddy',
        color: '#127391'
    },
    'others': {
        name: 'Others',
        color: '#6C6C6C'
    }
}

export const RegistrarColors = {
    'squarespace_domains ii llc': {
        name: 'Squarespace Domains II LLC',
        color: '#12AFB8'
    },
    'spacex_domains llc': {
        name: 'SpaceX Domains LLC',
        color: '#228EBC'
    },
    'pied_piper domains iv llc': {
        name: ' Pied Piper Domains IV LLC"',
        color: '#FE7754'
    }
}

export const WeakCipherColors = {
    'tls_1.0': {
        name: 'TLS 1.0',
        color: '#228EBC'
    },
    'tls_1.1': {
        name: 'TLS 1.1',
        color: '#6247AA'
    },
    'tls_1.2': {
        name: 'TLS 1.2',
        color: '#FE7754'
    },
    'tls_1.3': {
        name: 'TLS 1.3',
        color: '#C745F4'
    },
    'ssl': {
        name: 'SSL',
        color: '#7E8299'
    }
}

export const TechStacksColors = {
    'php': {
        name: 'PHP',
        color: '#12AFB8'
    },
    '.net': {
        name: '.NET',
        color: '#6247AA'
    },
    'java': {
        name: 'Java',
        color: '#FE7754'
    },
    'node.js': {
        name: 'Node.js',
        color: '#3ADA7A'
    },
    'others': {
        name: 'Others',
        color: '#CFE2F3'
    },
}

export const EASMInsight1Colors = {
    'SPF': {
        name: 'SPF',
        color: '#C745F4'
    },
    'DKIM': {
        name: 'DKIM',
        color: '#228EBC'
    },
    'DMARC': {
        name: 'DMARC',
        color: '#6247AA'
    }
}

export const SSLTLSColors = {
    'ssl': {
        name: 'SSL',
        color: "#FF0000",
    },
    'tls_1.0': {
        name: 'TLS 1.0',
        color: "#FF3300"
    },
    'tls_1.1': {
        name: 'TLS 1.1',
        color: '#FF6600'
    },
    'tls_1.2': {
        name: 'TLS 1.2',
        color: "#FFCC00"
    },
    'tls_1.3': {
        name: 'TLS 1.3',
        color: "#339933"
    },
}

export const assetCriticalityColors = {
    'mission_critical': {
        name: 'Mission Critical',
        color: "#F45F44",
    },
    'business_critical': {
        name: 'Business Critical',
        color: "#7C44BB"
    },
    'significant_impact': {
        name: 'Significant Impact',
        color: "#E4AB37"
    },
    'limited_impact': {
        name: 'Limited Impact',
        color: "#4DA2E5"
    },
    'negligible_impact': {
        name: 'Negligible Impact',
        color: "#3ADA7A"
    }
}

export const colorSelector = ["#9A2335", "#CC394F", "#FE7754", "#FFE765", "#CFE2F3", "#6247AA", "#D1105A", "#E4AB37", "#023E8A", "#127391", "#6C6C6C", "#12AFB8", "#E0F8F7", "#F65C5C"];

export const dynamicColorsSelector = ["#7C44BB", "#74C69D", "#4DA2E5", "#023E8A", "#00D7BB", "#CD5521"];

export const registrarsColorsSelector = ["#CD5521", "#00D7BB", "#023E8A", "#4DA2E5", "#6C6C6C", "#7C44BB"]

export const EDIT_ROLES_PLATFORM = [WEB_APP, API, MOBILE_APP];
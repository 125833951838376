import React from 'react';
import { BarChart, Bar, ResponsiveContainer, LabelList, Cell, CartesianGrid, XAxis, Tooltip, Text, YAxis } from 'recharts';
import Typography from '../Typography';
import Paper from '../Dashboard/Paper';
import { filterChartData, getItemType } from '../../utils/constants/severity';

const COLORS = ['var(--primary-500)', 'var(--tertiary-600)', 'var(--functional-purple)', 'var(--secondary-700)', 'var(--grey2)'];

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-graph-tooltip">
          <p className="label text-camel-case">{`${payload[0].payload.name}: ${payload[0].payload.value}`}</p>
        </div>
      );
    }
  
    return null;
};

const SimpleBarChart = (props) => {
    const {
        data = [],
        title,
        height,
        colors = COLORS,
        className,
        labelPosition = "right",
        shadow = false,
        chartClassName = null,
        showLabelCount,
        labelFullWidth = false,
        hideLabel = false,
        subText,
        action,
        type,
        xaxis = true,
        yaxis = true,
        angle = 0,
        xLabelHeight = 30,
        textAnchor = "middle",
    } = props;

    const CustomXAxisTick = ({ x, y, payload }) => {
        if (payload && payload.value) {
          return (
            <Text
                fontSize={"12px"}
                width={"10px"}
                height={"30px"}
                x={x} 
                y={y} 
                textAnchor={textAnchor} 
                verticalAnchor="start"
                angle={angle} // Rotate the text by -45 degrees
            >{payload.value}</Text>
          );
        }
        return null;
    };

    const total = showLabelCount ? 
        data?.reduce((acc, obj) => 
            obj?.name === showLabelCount ? 
                acc + obj?.value : acc + 0, 0) 
            : 
                data?.reduce((acc, obj) => acc + obj?.value, 0) || 0

    const handleBarClick = (data) => {
        if (action) {
            action(type, data?.name);
        }
    };

    return (
        <Paper shadow={shadow} className={`${className}`}>
            {
                title &&
                    <Typography variant={"subtitle1"} className={"text-headings px-4"}>{title}</Typography>
            }
            {
                subText &&
                    <Typography variant={"body2"} className={"px-4 mt-2 pb-5"}>{subText}</Typography>
            }
            <div className="grid grid-cols-12">
                <div className={`${hideLabel ? "col-span-12" : labelPosition === "right" ? "col-span-6" : "col-span-12"}`}>
                    <div className={`${chartClassName}`}>
                        <ResponsiveContainer width="100%" height={height ? height : 144}>
                            <BarChart 
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                                data={data}
                                onClick={(data) => {
                                    if (data && data.activePayload && data.activePayload.length > 0) {
                                        if (data.activePayload[0].payload?.value) {
                                            handleBarClick(data.activePayload[0].payload)
                                        }
                                    }
                                }}
                            >
                                <CartesianGrid stroke="#cccccc9e" strokeDasharray="3 3" opacity={0.8} />
                                {
                                    xaxis &&
                                        <XAxis 
                                            dataKey="name"
                                            interval={0}
                                            tick={CustomXAxisTick}
                                            height={xLabelHeight}
                                        />
                                }
                                {
                                    yaxis &&
                                        <YAxis allowDecimals={false} />
                                }
                                <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip />} />
                                <Bar barSize={27} dataKey="value" radius={[200, 200, 0, 0]}>
                                    {data.map((entry, index) =>  {
                                        const itemType = getItemType(entry);
                                        return (
                                            <Cell 
                                                key={`cell-${index}`}
                                                fill={colors[itemType]?.color}
                                                className={entry.value > 0 && action ? 'cursor-pointer' : ''}
                                            />
                                        )
                                    })}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                {!hideLabel && (
                    labelFullWidth ?
                        <div className="col-span-12 mt-4">
                            <div className='flex items-center flex-align-center'>
                                <div className='flex flex-col h-[130px]'>
                                    {
                                        data?.map((item, index) => {
                                            const itemType = getItemType(item);
                                            return (
                                                <div className="flex items-center flex-[100%]" key={index}>
                                                    <div className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5"
                                                        style={{background: colors[itemType]?.color}}></div>
                                                    <Typography variant={"caption"} className=""> 
                                                        <span className='mr-1.5'>{item.value}</span>
                                                        <span className='capitalize'>{item?.name[0]?.toUpperCase() + item?.name?.toLowerCase()?.slice(1)}</span>
                                                    </Typography>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    :
                        <div className={`${labelPosition === "right" ? "col-span-6" : "col-span-12"}`}>
                            <div className={`${labelPosition === "right" ? "flex flex-wrap items-center h-full gap-x-4 flex-align-center" : labelFullWidth ? "flex flex-wrap items-center h-[130px] gap-x-4 flex-align-center" : "flex flex-wrap items-center h-[40px] gap-x-4 flex-align-center"}`}>
                                {
                                    data?.map((item, index) => {
                                        const itemType = getItemType(item);
                                        return (
                                            <div className={`${labelPosition === "right" ? "flex-[100%]" : labelFullWidth ? "flex-[100%]" : ""}`} key={index}>
                                                <div className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5"
                                                    style={{background: colors[itemType]?.color}}></div>
                                                <Typography variant={"caption"}
                                                            className={`inline-block capitalize ${labelPosition === "bottom" && "!text-[9px]"} `}>{item.value} {item?.name[0]?.toUpperCase() + item?.name?.toLowerCase()?.slice(1)}</Typography>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                )}
            </div>
        </Paper>
    );
}

export default SimpleBarChart;

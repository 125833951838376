import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Text,
  LabelList,
} from 'recharts';
import { getItemType } from '../../utils/constants/severity';
import Typography from '../Typography';

const CustomTick = (props) => {
  const { x, y, payload } = props;
  const maxCharsPerLine = 10;

  const words = payload.value?.split(' ');
  const formattedText = [];
  let currentLine = '';

  words.forEach((word) => {
    if ((currentLine + word).length <= maxCharsPerLine) {
      currentLine += (currentLine ? ' ' : '') + word;
    } else {
      formattedText.push(currentLine);
      currentLine = word;
    }
  });

  if (currentLine) {
    formattedText.push(currentLine);
  }

  return (
    <g transform={`translate(${x},${y})`}>
      {formattedText.map((line, index) => (
        <Text
          key={index}
          x={0}
          y={index * 12}
          dy={index === formattedText.length - 1 ? 6 : 0} // Add slight padding for last line
          textAnchor="middle"
          fontSize={12}
          fill="#666"
          className="capitalize"
        >
          {line}
        </Text>
      ))}
    </g>
  );
};


const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white border border-gray-300 shadow-lg rounded-md max-w-[300px] break-words">
        <p className="label text-camel-case">{`${payload[0].payload.name.replace('_', ' ')}: ${
          payload[0].payload.value
        }`}</p>
      </div>
    );
  }

  return null;
};

const BarChartComponent = (props) => {
  const {
    className,
    graphHeight,
    labelHeight,
    Yaxis = true,
    showLabelValue = false,
    labelAppend = '',
    graphData,
    showToolTip = true,
    showLabelList = false,
    barSize = 30,
    colors = [],
  } = props;
  
  return (
    <div className={`${className ? className : ''} stacked-bar`}>
      <div className={`graph-container ${graphHeight ? graphHeight : ''}`}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={500} height={300} data={graphData}>
            <CartesianGrid stroke="#cccccc9e" strokeDasharray="3 3" opacity={0.8} />
            <XAxis
              dataKey="name"
              interval={0}
              tick={<CustomTick />}
              height={50}
              tickMargin={10}
            />

            {Yaxis && (
              <YAxis
                allowDecimals={false}
                type="number"
                style={{ fontSize: '14px', textTransform: 'capitalize' }}
              />
            )}
            {showToolTip && (
              <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
            )}
            <Bar barSize={barSize} dataKey="value" radius={[200, 200, 0, 0]}>
              {showLabelList && (
                <LabelList dataKey="value" position="insideBottom" fill="#fff" fontWeight={700} />
              )}
              {graphData?.map((entry, index) => {
                let itemType;
                if (entry?.refKey) {
                  itemType = getItemType(entry);
                } else {
                  itemType = entry?.name;
                }
                return <Cell key={`cell-${index}`} fill={colors[itemType]?.color} />;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="label">
        <div className="flex items-center flex-[100%] flex-align-center">
          <div className={`flex flex-col ${labelHeight ? labelHeight : ''}`}>
            {graphData?.map((item, index) => {
              let itemType;
              if (item?.refKey) {
                itemType = getItemType(item);
              } else {
                itemType = item?.name;
              }
              return (
                <div className="flex items-center flex-[100%]" key={index}>
                  <div
                    className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5"
                    style={{ background: colors[itemType]?.color }}
                  ></div>
                  <Typography variant={'caption'} className="capitalize">
                    <span className="inline-block capitalize">
                      {showLabelValue ? item?.value : ''} {colors[itemType]?.name} {labelAppend}
                    </span>
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarChartComponent;

import React, { useContext, useEffect, useState } from 'react';
import { AssetCriticality } from '../../utils/constants';
import MuiTooltip from '../MuiComponents/MuiTooltip';
import ReactSelectSingleSelect from './ReactSelectSingleSelect';
import { components } from 'react-select';
import { AlertContext } from '../SuccessOrFailedModal';
import { useUpdateAssetById } from '../../hooks/asset';
import MainLoader from '../Loader/MainLoader';

const AssetCriticalityDropDown = ({
    className,
    width,
    placeholder,
    borderColor,
    border,
    extraTitle = "",
    value,
    menuIsOpen,
    components,
    assetId,
    renderStyles=true,
    placeholderColor='var(--primary-800)',
    menuBorderRadius=8,
    pentestAssetId = null,
    criticalityUpdateAction = 'pentest',
}) => {


    const totalLength = Object.keys(AssetCriticality).length
    const criticalityOptions = Array.from({ length: totalLength}, (_, index) => {
        const currentValue = index + 1;
        const criticality = "P".concat(currentValue.toString())
        return {
            value: criticality,
            label: (
                <>
                    <MuiTooltip title={
                        <div style={{ whiteSpace: 'pre-line' }}>
                            {AssetCriticality[criticality].description.map((item) => (
                                <li key={item}>{item}</li>
                            ))}
                        </div>} placement="right">
                        <span style={{ paddingRight: "5px" }}>{extraTitle.concat(AssetCriticality[criticality].title)}</span>
                    </MuiTooltip>
                </>
            )
        };
    });


    const defaultOption = value ? criticalityOptions.find(option => option.value === value) : null;
    const [selectedCriticality, setSelectedCriticality] = useState(value);
   
    const [selectedOption, setSelectedOption] = useState(defaultOption);


    const [popup] = useContext(AlertContext)
    const mutation = useUpdateAssetById(assetId);

    useEffect(() => {
        async function handleCriticalityUpdate() {
            if (selectedCriticality && value !== selectedCriticality) {
                const confirmed = await popup({
                    title: "Are you sure?",
                    variant: "",
                    description: criticalityUpdateAction === "pentest" 
                        ? "Asset Criticality will be updated for Inflight and Upcoming Pentests" 
                        : "Asset Criticality will be updated.",
                    okayText: "Yes",
                    cancelText: "No"
                });

                if (confirmed) {
                    await mutation.mutateAsync({
                        priority: selectedCriticality,
                        pentestAssetId
                    });
                }
                setSelectedCriticality(null);
            }
        }
        handleCriticalityUpdate();
    }, [selectedCriticality]);

    const colourStyles = {
        indicatorSeparator: () => ({ display: 'none' }),
        dropdownIndicator: base => ({
            ...base,
            color: "var(--primary-800)"
        }),
        menu: base => ({
            ...base,
            zIndex: 99,
            borderRadius: menuBorderRadius,
            marginTop: 0,

        }),
        menuList: base => ({
            ...base,
            padding: 0,

        }),
        control: (base, state) => ({
            ...base,
            border,
            borderRadius: 8,
            borderColor,
            boxShadow: state.isFocused ? null : null,
            borderColor: "var(--primary-500)",
            "&:hover": {
                borderColor: "var(--primary-900)",
            },
            width,
        }),
        option: (provided, state) => ({
            ...provided,
            // color: AssetCriticality[state.value].color,
            fontSize: '14px',
            backgroundColor: state.isSelected ? "var(--primary-500)" : "white",
            "&:hover": {
                backgroundColor: state.isSelected ? "var(--primary-300)" : "var(--background)",
            },
            "& span": {
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            "&:hover span": {
                overflow: 'visible',
                whiteSpace: 'normal',
            },
            fontWeight: 400,
            letterSpacing: '1px',
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '14px',
            color: placeholderColor,
            fontWeight: renderStyles ? 600 : null,
            letterSpacing: renderStyles ? '1px' : null
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '14px',
            fontWeight: renderStyles ? 600 : null,
            letterSpacing: renderStyles ? '1px' : null
        }),
        
    }
    
    return (
        <div>
           {mutation?.isLoading && <MainLoader />}
            <ReactSelectSingleSelect
                name={'asset-criticality-dropdown'}
                className={`${className}`}
                options={criticalityOptions}
                styles={colourStyles}
                placeholder={placeholder}
                onChange={(options) => {
                    setSelectedCriticality(options.value);  
                }}
                isSearchable={false}
                value={selectedOption}
                menuIsOpen={menuIsOpen}
                components={components} 
            />
        </div>
    );
};

export default AssetCriticalityDropDown;

import React from 'react';

const UncheckTick = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="14.5" fill="#E0F8F7" stroke="#12AFB8" stroke-dasharray="2 2"/>
            <g clip-path="url(#clip0_14139_80359)">
                <path d="M12.4982 18.475L9.02318 15L7.83984 16.175L12.4982 20.8333L22.4982 10.8333L21.3232 9.65833L12.4982 18.475Z" fill="#E9E9E9"/>
            </g>
            <defs>
                <clipPath id="clip0_14139_80359">
                    <rect width="20" height="20" fill="white" transform="translate(5 5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default UncheckTick;
